export default function giveCurrencyMultinet() {
    let giveCurrency = this.exchange.currency.give;

    if (giveCurrency.isMultinet && giveCurrency.crypto) {
        let netList = this.currenciesGive.filter(c => c.crypto && c.isMultinet && c.code == giveCurrency.code);
        if (netList.length > 1) {
            return netList;
        }
        return [];
    } else {
        return [];
    }
}
