const state = {
    currentOrder: null,
    orderData: null,
    orderToken: localStorage.getItem("orderToken")
};

const mutations = {
    UPDATE_ORDER(state, order) {
        if (state.currentOrder && order._id === state.currentOrder._id) {
            state.currentOrder = Object.assign({}, state.currentOrder, order);
        }
    },
    SET_ORDER_DATA(state, data) {
        state.orderData = data;
    },
    SET_ORDER_TOKEN(state, token) {
        localStorage.setItem("orderToken", token);
        state.orderToken = token;
    },
    SET_CURRENT_ORDER(state, order) {
        state.currentOrder = order;
    }
};

const getters = {
    orderData(state) {
        return state.orderData;
    },
    orderToken(state) {
        return state.orderToken;
    },
    currentOrder(state) {
        return state.currentOrder;
    }
};

export default {
    state,
    actions: {},
    mutations,
    getters
};
