import axios from "axios";

class CardService {
    getAll() {
        return axios.get("profile/cards");
    }
    add(data) {
        return axios.post("profile/cards", data);
    }
    reverify(data, hash) {
        // +
        return axios.put(`profile/cards/reverification?hash=${hash}`, data);
    }
}

const cardService = new CardService();

export default cardService;
