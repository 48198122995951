import axios from "axios";

class OrderService {
    getOneByHash(hash) {
        return axios.get("/orders/" + hash);
    }
    getAll() {
        return axios.get("profile/orders");
    }
    create(data) {
        return axios.post("orders", data);
    }
    change(data) {
        //+
        return axios.put("profile/orders", data);
    }
    changeAmount(data) {
        // +
        return axios.put("profile/orders/amount", data);
    }
    // changeDirection(data, auth) {
    //     if (auth) {
    //         return axios.put("profile/orders/direction", data);
    //     } else {
    //         let orderToken = localStorage.getItem("orderToken");
    //         if (orderToken) {
    //             return axios.put("profile/newDirectionUnauth", data, {
    //                 headers: {
    //                     orderToken: orderToken
    //                 }
    //             });
    //         } else {
    //             return null;
    //         }
    //     }
    // }
    // getUnauthOrder() {
    //     let orderToken = localStorage.getItem("orderToken");
    //     if (orderToken) {
    //         return axios.get("profile/activeOrder", {
    //             headers: {
    //                 orderToken: orderToken
    //             }
    //         });
    //     }
    // }
}

const orderService = new OrderService();

export default orderService;
