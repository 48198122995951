<template>
    <div class="cell cell--4-col cell--4-col-tablet cell--4-col-phone order">
        <div class="info">
            <div class="status-text-with-number">
                <span class="number">
                    {{ order._id }}
                </span>

                <span class="status-text" :class="status">
                    {{ statusText }}
                </span>
            </div>
            <div class="date">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="1 1 24 24" fill="none" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock">
                    <circle cx="12" cy="12" r="10"></circle>
                    <polyline points="12 6 12 12 16 14"></polyline>
                </svg>
                {{
                    moment(order.created)
                        .lang($i18n.locale)
                        .format("DD MMMM YYYY, HH:mm")
                }}
            </div>
        </div>

        <div class="currency take">
            <img alt="" class="image" :src="image.take" v-if="image.take" />
            <div class="details">
                <div class="amount">{{ order.amount.take }} {{ order.currency.take.code }}</div>
                <div class="name">{{ order.currency.take.name }}</div>
            </div>
        </div>

        <div class="icon-exchange">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="-3 2 24 24" fill="none" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-down">
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <polyline points="19 12 12 19 5 12"></polyline>
            </svg>
        </div>

        <div class="currency give">
            <img alt="" class="image" :src="image.give" v-if="image.give" />
            <div class="details">
                <div class="amount">{{ order.amount.give }} {{ order.currency.give.code }}</div>
                <div class="name">{{ order.currency.give.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";

export default {
    props: {
        order: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        moment
    },
    computed: {
        image() {
            return {
                take: !this.objectEmpty(this.order) ? `${this.URL.server}/images/${this.order.currency.take.image}.png` : "",
                give: !this.objectEmpty(this.order) ? `${this.URL.server}/images/${this.order.currency.give.image}.png` : ""
            };
        },
        status() {
            switch (this.order.status) {
                case 101:
                    return "verification";
                case 102:
                    return "payment-user";
                case 103:
                    return "check-payment";
                case 104:
                    return "payment-service";
                case 105:
                    return "resolved";
                case 106:
                    return "rejected";
                case 107:
                    return "checked";
                case 108:
                    return "error";
                default:
                    return null;
            }
        },
        statusText() {
            switch (this.status) {
                case "resolved":
                    return this.$t("orderCard.statusName.completed");
                case "rejected":
                    return this.$t("orderCard.statusName.rejected");
                case "checked":
                    return this.$t("orderCard.statusName.completed");
                default:
                    return this.$t("orderCard.statusName.new");
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.order {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $border-sm;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.32);
}

.info {
    padding: 16px 12px;
    border-bottom: 1.2px solid rgba(0, 0, 0, 0.12);
}

.status-text-with-number {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.status-text-with-number .status-text {
    color: $white;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: $border-sm;
}

.status-text-with-number .number {
    color: $grafit;
    font-size: 20px;
    font-weight: 500;
}

.info .date {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $grey-lg-text;
    font-size: 14px;
}

.info .date svg {
    margin-right: 8px;
}

.currency {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 12px;
}

.currency.take {
    padding-top: 16px;
}

.currency.give {
    padding-bottom: 16px;
}

.currency .image {
    -o-object-fit: cover;
    object-fit: cover;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 12px;
}

.currency .details {
    padding-top: 4px;
    width: 100%;
}

.currency .details .amount {
    color: $cur-name;
    font-size: 18px;
    margin-bottom: 8px;
}

.currency .details .name {
    color: $cur-name;
    font-size: 14px;
}

.icon-exchange {
    color: $cur-name;
    padding: 0 12px 0 14px;
    margin-bottom: 8px;
}

.status-text.verification {
    background-color: $yellow;
}

.status-text.payment-user {
    background-color: $yellow;
}

.status-text.check-payment {
    background-color: $yellow;
}

.status-text.payment-service {
    background-color: $yellow;
}

.status-text.rejected {
    background-color: $red;
}

.status-text.resolved {
    background-color: $green;
}

.status-text.checked {
    background-color: $green;
}

.status-text.error {
    background-color: $red;
}
</style>
