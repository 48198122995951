import axios from "axios";

// exchangeV2
class ExchangeService {
    getAll() {
        return axios.get("exchangeV2");
    }
    getOne(params) {
        return axios.get("/one-exchange", { params });
    }
}

const exchangeService = new ExchangeService();

export default exchangeService;
