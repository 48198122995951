export default function currenciesGive() {
    if (this.giveActiveFilter) {
        return this.allCurrenciesGive
            .filter(c => c.type === this.giveActiveFilter)
            .sort(this.sortCurrencies)
            .sort((a, b) => a.sort - b.sort);
    } else {
        return [];
    }
}
