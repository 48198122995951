<template lang="">
    <div class="notif-error">
        <p>
            {{ $t("notifInfo.first") }}
        </p>
        <p>
            {{ $t("notifInfo.second") }}
        </p>
        <p>
            {{ $t("notifInfo.third") }}
        </p>
    </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.notif-error {
    color: red;
    font-size: 14px;
    padding: 16px;
}

.notif-error p {
    margin-bottom: 8px;
}
</style>
