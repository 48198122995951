export default function rateHTML() {
    if (!this.exchange) return "";
    let rate = this.exchange.rate;
    let leftAmount;
    let rightAmount;
    let leftCode = this.exchange.currency.take.code;
    let rightCode = this.exchange.currency.give.code;
    if (rate.reverse == "left") {
        leftAmount = Math.floor(rate.service * this.precision.take) / this.precision.take;
        rightAmount = 1;
    } else if (rate.reverse == "right") {
        leftAmount = 1;
        rightAmount = Math.floor(rate.service * this.precision.give) / this.precision.give;
    } else {
        leftAmount = 1;
        rightAmount = 1;
    }

    return `${leftAmount} <span style="color: #565656">${leftCode}</span> <i>=</i> ${rightAmount} <span  style="color: #565656">${rightCode}</span>`;
}
