<template>
    <div class="faq-item" @click="selectFAQ">
        <div class="question">
            {{ faq.question }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        faq: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        selectFAQ() {
            this.$emit("select-faq", this.faq);
        }
    }
};
</script>

<style lang="scss" scoped>
.faq-item {
    position: relative;
    padding: 12px 16px;
}

.faq-item:not(.current):hover:after,
.faq-item.current:after {
    height: 100%;
    width: 2px;
    display: block;
    content: " ";
    top: 0;
    right: -18px;
    position: absolute;
    background-color: $orange;
}

.faq-item:not(:last-child) {
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.16);
}

.question {
    cursor: pointer;
    color: $grey-text;
    font-size: 16px;
    line-height: 22px;
    text-decoration: underline;
    margin-bottom: 8px;
}

.faq-item.current .question {
    color: $orange;
}

@media (max-width: 739px) {
    .faq-item {
        padding: 12px 0;
    }
}
</style>
