export default function currenciesTake() {
    if (!this.takeActiveFilter) return [];

    let exchanges = this.exchanges;
    return exchanges
        .map(e => e.take)
        .filter(c => c.type === this.takeActiveFilter)
        .filter((c, i, arr) => {
            return arr.findIndex(item => item._id === c._id) === i;
        })
        .sort(this.sortCurrencies)
        .sort((a, b) => a.sort - b.sort);
}
