<template>
    <div class="news-item" @click="selectNews">
        <div class="title">
            {{ news.title }}
        </div>
        <div class="date">
            {{ date }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        news: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        date() {
            let d = new Date(this.news.created);
            d = ["0" + d.getDate(), "0" + (d.getMonth() + 1), "" + d.getFullYear(), "0" + d.getHours(), "0" + d.getMinutes()];
            for (let i = 0; i < d.length; i++) {
                if (i !== 2) {
                    d[i] = d[i].slice(-2);
                }
            }
            let monthName =
                d[1] === "01"
                    ? "января"
                    : d[1] === "02"
                    ? "февраля"
                    : d[1] === "03"
                    ? "марта"
                    : d[1] === "04"
                    ? "апреля"
                    : d[1] === "05"
                    ? "мая"
                    : d[1] === "06"
                    ? "июня"
                    : d[1] === "07"
                    ? "июля"
                    : d[1] === "08"
                    ? "августа"
                    : d[1] === "09"
                    ? "сентября"
                    : d[1] === "10"
                    ? "октября"
                    : d[1] === "11"
                    ? "ноября"
                    : "декабря";
            d[1] = monthName;
            return d.slice(0, 3).join(" ") + ", в " + d.slice(3).join(":");
        }
    },
    methods: {
        selectNews() {
            this.$emit("select-news", this.news);
        }
    }
};
</script>

<style lang="scss" scoped>
.news-item {
    position: relative;
    padding: 12px 16px;
}

.news-item:not(.current):hover:after,
.news-item.current:after {
    height: 100%;
    width: 2px;
    display: block;
    content: " ";
    top: 0;
    right: -18px;
    position: absolute;
    background-color: $orange;
}

.news-item:not(:last-child) {
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.16);
}

.title {
    cursor: pointer;
    color: $grey-text;
    font-size: 16px;
    line-height: 22px;
    text-decoration: underline;
    margin-bottom: 8px;
}

.news-item.current .title {
    color: $orange;
}

.date {
    color: $grey-lg-text;
    font-size: 14px;
}

@media (max-width: 739px) {
    .news-item {
        padding: 12px 0;
    }
}
</style>
