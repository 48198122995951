<template>
    <div class="faq">
        <div class="grid grid--no-spacing card">
            <div class="cell cell--4-col cell--3-col-tablet list-faq">
                <item-faq :class="{ current: current._id === faq._id }" v-for="faq in faqs" :faq="faq" :key="faq._id" @select-faq="selectFaq"></item-faq>
            </div>
            <div class="cell cell--8-col cell--5-col-tablet">
                <div class="question">
                    {{ current.question }}
                </div>
                <div class="answer" v-html="current.answer"></div>
            </div>
        </div>
    </div>
</template>

<script>
import itemFaq from "../components/item/faq.vue";
import { mapGetters } from "vuex";

export default {
    components: {
        itemFaq
    },
    data() {
        return {
            current: {}
        };
    },
    computed: {
        ...mapGetters(["faqs"])
    },
    methods: {
        selectFaq(faq) {
            if (this.current._id !== faq._id) {
                this.current = faq;
            }
        }
    },
    mounted() {
        if (this.faqs.length !== 0) {
            this.current = this.faqs[0];
        }
    }
};
</script>

<style lang="scss" scoped>
.faq {
    color: $grey-text;
    font-size: 16px;
}

.faq .page-name {
    text-align: center;
    font-size: 32px;
    margin: 32px 0;
}

.faq .card {
    background-color: $white;
    flex-grow: 1;
    margin: 16px;
    padding: 24px 32px;
    border-radius: 16px;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
}

.faq .card > .cell:nth-child(1) {
    padding-right: 16px;
    box-shadow: 2px 0 2px -2px rgba(0, 0, 0, 0.16);
}

.faq .card > .cell:nth-child(2) {
    padding-left: 32px;
}

.faq .card > .cell > .question {
    color: $grafit;
    font-size: 26px;
    margin-bottom: 24px;
}

.faq .card > .cell > .answer {
    line-height: 24px;
}

.faq .card > .cell > .answer > img {
    width: 100% !important;
    height: auto;
}

@media (max-width: 739px) {
    .faq .card {
        margin: 8px;
        padding: 24px 16px;
    }
    .faq .card > .cell:nth-child(2) {
        padding-left: 0;
    }
    .faq .card .list-faq {
        margin-bottom: 24px;
    }
}
</style>
