export default function getPromocodeTextInfo() {
    let amount = this.getPromocodeGiveAmount;
    if (!amount) return null;
    let exchange = this.exchange;
    if (!exchange) return null;
    let { code } = exchange.currency.give;

    let text = this.$t("exchanger.getPromocodeTextInfo", { promocode: this.promocodeInfo.code, amount, code });

    return text;
}
