export default function allCurrenciesGive() {
    let selected = this.selected.take;
    if (!selected) return [];

    let exchanges = this.exchanges;
    exchanges = exchanges.reduce((arr, e) => {
        if (e.take._id === selected._id) {
            arr.push(e);
        }
        return arr;
    }, []);
    return exchanges.map(e => e.give);
}
