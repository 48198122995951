<template>
    <div>
        <div class="header">
            <img alt="" class="spinner" src="/assets/spinner.gif" v-if="spinner" />
            <div class="header-name">{{ name }}</div>
            <div class="icon-close" @click="hideHandler">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 -4 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </div>
        </div>
        <div class="border"></div>
    </div>
</template>

<script>
export default {
    props: {
        name: String,
        spinner: {
            type: Boolean,
            default: false
        },
        hideHandler: Function
    }
};
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 16px 16px 0px 16px;
}
.header > .spinner {
    height: 32px;
    width: 32px;
    margin-right: 16px;
}
.header-name {
    font-size: 24px;
    line-height: 30px;
    color: #565656 !important;
}
.icon-close {
    cursor: pointer;
    color: $black;
}
.border {
    height: 1px;
    width: 100%;
    background-color: #ebeff6;
    margin: 12px 0;
    border-radius: 4px;
}
</style>
