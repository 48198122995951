import axios from "axios";

class OrderAcquiringService {
    create(data) {
        return axios.post("/order-acquiring", data);
    }

}

const orderAcquiringService = new OrderAcquiringService();

export default orderAcquiringService;
