// Функция для чтения значения куки по имени
function setStorageValue(key, value) {
    localStorage.setItem(key, value);
}

// Функция для добавления новой куки
function getStorageValue(key) {
    return localStorage.getItem(key);
}

export { setStorageValue, getStorageValue };
