import sumWithPrecision from "./sumWithPrecision";
import getExchangeAmount from "./getExchangeAmount";

export default function getPriceExchangeCurrencyInRub(exchange, sum, direction, atRateService = false) {
    const formula = exchange.rate.formula;
    const takeCurrency = exchange.currency.take;
    const giveCurrency = exchange.currency.give;
    sum = +sum;

    if (direction == "take") {
        let result;
        const code = takeCurrency.code;
        const value = takeCurrency.evalue;
        const precision = takeCurrency.precision;

        if (code === "RUB") {
            result = sumWithPrecision({ precision, sum });
        } else {
            result = sumWithPrecision({ precision, sum: sum * value });
        }

        if (atRateService) {
            result = sumWithPrecision({ precision, sum: result * formula });
        }

        return result;
    } else {
        const value = giveCurrency.evalue;
        const precision = giveCurrency.precision;

        let result;
        if (atRateService) {
            result = getExchangeAmount(exchange, "take", sum, true);
        } else {
            result = sumWithPrecision({ precision, sum: value * sum });
        }
        return result;
    }
}
