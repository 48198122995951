<template>
    <div class="select" v-click-outside="close">
        <div class="selected" @click="toggle" v-if="selected">
            <img alt="" class="image" :src="`${server()}/images/${selected.image}.png`" />
            <div>
                {{ selected.name }}
            </div>
        </div>

        <div class="options" v-show="isOpen" v-if="options.name !== selected.name">
            <div class="option" v-for="option in options" :key="option._id" @click="change(option)">
                <img alt="" class="image" :src="`${server()}/images/${option.image}.png`" />
                <div>
                    {{ option.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            selected: null,
            isOpen: false
        };
    },
    computed: {},
    watch: {
        options: {
            immediate: true,
            handler() {
                if (!this.selected && !this.arrayEmpty(this.options)) {
                    this.selected = this.options[0];
                    this.$emit("change-option", this.options[0]);
                }
            }
        }
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
        },
        close() {
            this.isOpen = false;
        },
        change(option) {
            this.selected = option;
            this.$emit("change-option", option);
            this.close();
        },
        server(){
            return this.URL.server;
        }
    }
};
</script>

<style lang="scss" scoped>
.select {
    position: relative;
    font-family: "Gotham Pro", -apple-system, system-ui, BlinkMacSystemFont, “Segoe UI”, Roboto, “Helvetica Neue”, Arial, sans-serif;
    margin: 16px 0;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.selected {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $grafit;
    margin: 0;
    padding: 12px 0;
    border-bottom: 1.2px solid rgba(0, 0, 0, 0.12);
}
.selected .image {
    margin-left: 1rem;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 16px;
    -o-object-fit: cover;
    object-fit: cover;
}
.options {
    width: 100%;
    position: absolute;
    background-color: $white;
    padding: 4px 0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.32);
    z-index: 2;
}
.option {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: $grafit;
    padding: 8px 16px;
    margin: 8px 0;
}
.option:hover {
    background-color: #ff88002b;
}
.option .image {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 16px;
    -o-object-fit: cover;
    object-fit: cover;
}
</style>
