<template lang="">
    <h6 class="rejectedSection">
        {{ comment }}
    </h6>
</template>
<script>
export default {
    props: {
        order: Object
    },
    computed: {
        comment() {
            let order = this.order;
            return order?.comment && order?.comment?.client ? order?.comment?.client : this.$t("popupOrder.rejectedOrder.orderComment");
        }
    }
};
</script>
<style lang="scss">
.rejectedSection {
    text-align: center;
    margin-top: 10px;
}
</style>
