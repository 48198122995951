<template lang="">
    <ModalContentWrap :headerName="$t('popupCard.rejectedCard.title', { number: card.number })" :closeHandler="closeHandler">
        <div class="rejectedCardSection">
            <StepItem :number="1" :text="$t('popupCard.rejectedCard.step1')" />

            <div class="rejectedCardSection_description">
                <p>{{ $t("popupCard.rejectedCard.desc1", { number: number }) }}</p>
                <div class="rejectedCardSection_descriptionImport">
                    {{ $t("popupCard.rejectedCard.desc2") }}
                    <br />
                    {{ $t("popupCard.rejectedCard.desc3") }}
                    <br />
                    {{ $t("popupCard.rejectedCard.desc4") }}
                </div>
            </div>
            <div class="rejectedCardSection_errorComment">
                <span>{{ $t("popupCard.rejectedCard.error1") }}</span
                ><br />

                <div v-if="comment" class="rejectedCardSection_errorCommentText">{{ $t("popupCard.rejectedCard.commentText") }} "{{ comment }}"</div>
            </div>

            <CardPhotoExample v-if="!image" />
            <StepItem :number="2" :text="addImageText" />
            <ImageSelect :image="image" :file="file" :removeImage="removeImage" :isScreenMobile="isScreenMobile" :setImage="setImage" :setFile="setFile" />
        </div>
        <template v-slot:btns>
            <div class="rejectedCardSection_btnsWrap">
                <my-button class="outline" :outline="true" :value="$t('popupCard.rejectedCard.confirm.cancel')" @click.native="closeHandler"></my-button>
                <my-button :value="$t('popupCard.rejectedCard.confirm.send')" @click.native="reverifyCardHandler" :spinner="spinner" />
            </div>
        </template>
    </ModalContentWrap>
</template>
<script>
// Store
import {mapGetters} from 'vuex';

// Components
import ModalContentWrap from "../ModalContentWrap/ModalContentWrap.vue";
import ImageSelect from "../ui/ImageSelect.vue";
import CardPhotoExample from "./UI/CardPhotoExample.vue";

// Computeds
import addImageText from "./computeds/addImageText";
import isScreenMobile from "./computeds/isScreenMobile";

// Service
import CardService from "../../services/CardService";

// UI
import StepItem from "../ui/StepItem.vue";
import MyButton from "../ui/button.vue";

export default {
    data() {
        return {
            image: null,
            file: null,
            spinner: false
        };
    },
    props: {
        number: Number,
        closeHandler: Function,
        card: Object
    },
    computed: {
        ...mapGetters(['isAuthenticated']),
        addImageText,
        isScreenMobile,
        comment() {
            let text = this.card?.comment?.client;
            return text;
        }
    },
    methods: {
        async reverifyCardHandler() {
            try {
                if (this.spinner) return;
                if (!this.file) return;

                this.spinner = true;

                let form = new FormData();
                form.append("image", this.file);
                await CardService.reverify(form, this.card.hash);
            } catch (err) {
                console.error(err);
            } finally {
                this.spinner = false;
            }
        },
        removeImage() {
            this.file = null;
            this.image = null;
        },
        setImage(i) {
            this.image = i;
        },
        setFile(f) {
            this.file = f;
        }
    },
    beforeDestroy() {
        this.image = null;
        this.file = null;
        this.spinner = false;
    },
    components: {
        ModalContentWrap,
        StepItem,
        ImageSelect,
        CardPhotoExample,
        MyButton
    }
};
</script>
<style lang="scss" scoped>
.rejectedCardSection_description {
    font-size: 14px;
    line-height: 20px;
    background-color: $white;
    padding: 16px;
}

.rejectedCardSection_descriptionImport {
    font-size: 14px;
    line-height: 20px;
    background-color: $white;
    padding: 0px 16px;
    margin-top: 5px;
    font-weight: 600;
}

.rejectedCardSection_description > p {
    margin-bottom: 16px;
}

.rejectedCardSection_errorComment {
    color: red;
    text-align: center;
    margin-top: 10px;
}

.rejectedCardSection_errorCommentText {
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
}

.rejectedCardSection_btnsWrap {
    display: flex !important;
    justify-content: center;
}

.rejectedCardSection_btnsWrap button:first-child {
    margin-right: 10px;
    display: inline-block;
}
.rejectedCardSection_btnsWrap button:last-child {
    margin-left: 10px;
}
</style>
