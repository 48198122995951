export default function validateInputTake() {
    this.validations.take.isValid = true;
    this.validations.take.text = "";

    let exchange = this.exchange;
    if (!exchange) return;

    let take = exchange.currency.take;
    let give = exchange.currency.give;

    let min = take.code == "BTC" || give.code == "BTC" ? this.limit.min : exchange.limit.min;

    if (min) {
        if (this.amount.take !== 0 && this.amount.take < +min) {
            this.validations.take.isValid = false;
            this.validations.take.text = this.$t("exchanger.validateInputTake.minError", { min, code: take.code });
        }
    }
    if (exchange.limit.max) {
        if (this.amount.take !== 0 && this.amount.take > +this.limit.max) {
            this.validations.take.isValid = false;
            this.validations.take.text = this.$t("exchanger.validateInputTake.maxError", { max: this.limit.max, code: take.code });
        }
    }
}
