export default function(amount) {
    let amountGive = +amount;

    let take = this.getExchangeAmount(this.exchange, "take", amountGive);
    const commissionBTC = this.getCommissionBTC(take, amountGive, "give");
    // console.log({ commissionBTC });
    if (commissionBTC) {
        const calcAmountTake = this.getExchangeAmount(this.exchange, "take", +amountGive + commissionBTC);
        if (calcAmountTake > 0) {
            take = calcAmountTake;
        } else {
            take = 0;
        }
    }

    const commissionQiwi = this.getCommissionQiwi;
    if (commissionQiwi) {
        const addGive = amountGive * commissionQiwi;
        const newGive = amountGive + addGive;
        const calcAmountTake = this.getExchangeAmount(this.exchange, "take", newGive);
        if (calcAmountTake > 0) {
            take = calcAmountTake;
        } else {
            take = 0;
        }
    }

    let promocodeSum = this.getPromocodeGiveAmount;

    if (promocodeSum) {
        const newGive = amountGive - promocodeSum;
        const calcAmountTake = this.getExchangeAmount(this.exchange, "take", newGive);
        if (calcAmountTake > 0) {
            take = calcAmountTake;
        } else {
            take = 0;
        }
    }
    this.amount.take = take;
}
