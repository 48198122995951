export default function (field, side) {
    if (side == "take") {
        this.validationTakeFields[field._id] = {
            isValid: true,
            text: ""
        };
    } else if (side == "give") {
        this.validationGiveFields[field._id] = {
            isValid: true,
            text: ""
        };
    }
}
