const currenciesTake = {
    immediate: true,
    handler() {
        const list = this.currenciesTake;
        const selected = this.selected.take;

        if (this.pairLink && this.pairLink.exc) {
            this.selected.take = this.pairLink.exc.take;
        } else if (!selected && !this.arrayEmpty(list)) {
            this.selected.take = list[0];
        } else if (list.findIndex(c => c._id === selected._id) === -1 && !this.arrayEmpty(list)) {
            this.selected.take = list[0];
        }
    }
};

export default currenciesTake;
