<template>
    <div class="dialog-container" :class="classes" @keyup.esc.stop="closeOnEsc" tabindex="0">
        <div class="dialog" ref="dialog" :style="styles" :class="dialogClasses">
            <slot></slot>
        </div>

        <backdrop class="dialog-backdrop" :class="classes" v-if="backdrop" ref="backdrop" @close="clickOutsideToClose && close()"></backdrop>
    </div>
</template>

<script>
import backdrop from "./backdrop.vue";

function transitionEndEventName() {
    const el = document.createElement("span");
    const transitions = {
        transition: "transitionend",
        OTransition: "oTransitionEnd",
        MozTransition: "transitionend",
        WebkitTransition: "webkitTransitionEnd"
    };

    for (let transition in transitions) {
        if (el.style[transition] !== undefined) {
            return transitions[transition];
        }
    }
}

export default {
    props: {
        clickOutsideToClose: {
            type: Boolean,
            default: true
        },
        escToClose: {
            type: Boolean,
            default: true
        },
        backdrop: {
            type: Boolean,
            default: true
        },
        noSpacing: {
            type: Boolean,
            default: false
        },
        openFrom: String,
        closeTo: String,
        fullscreen: {
            type: Boolean,
            default: false
        },
        closePopup: Function
    },
    data: () => ({
        active: false,
        transitionOff: false,
        dialogTransform: ""
    }),
    computed: {
        classes() {
            return {
                active: this.active
            };
        },
        dialogClasses() {
            return {
                fullscreen: this.fullscreen,
                "transition-off": this.transitionOff,
                reference: this.openFrom || this.closeTo,
                "no-spacing": this.noSpacing
            };
        },
        styles() {
            return {
                transform: this.dialogTransform
            };
        }
    },
    methods: {
        removeDialog() {
            if (document.body.contains(this.dialogElement)) {
                this.$el.parentNode.removeChild(this.$el);
            }
        },
        calculateDialogPos(ref) {
            const reference = document.querySelector(ref);

            if (reference) {
                const openFromRect = reference.getBoundingClientRect();
                const dialogRect = this.dialogInnerElement.getBoundingClientRect();
                const widthInScale = openFromRect.width / dialogRect.width;
                const heightInScale = openFromRect.height / dialogRect.height;
                let distance = {
                    top: -(dialogRect.top - openFromRect.top),
                    left: -(dialogRect.left - openFromRect.left + openFromRect.width)
                };

                if (openFromRect.top > dialogRect.top + dialogRect.height) {
                    distance.top = openFromRect.top - dialogRect.top;
                }

                if (openFromRect.left > dialogRect.left + dialogRect.width) {
                    distance.left = openFromRect.left - dialogRect.left - openFromRect.width;
                }

                this.dialogTransform = `translate3D(${distance.left}px, ${distance.top}px, 0) scale(${widthInScale}, ${heightInScale})`;
            }
        },
        open() {
            document.body.appendChild(this.dialogElement);
            this.transitionOff = true;
            this.calculateDialogPos(this.openFrom);

            window.setTimeout(() => {
                this.dialogElement.focus();
                this.transitionOff = false;
                this.active = true;
            });

            this.$emit("open");
        },
        closeOnEsc() {
            if (this.escToClose) {
                this.close();
            }
        },
        close() {
            if (document.body.contains(this.dialogElement)) {
                this.$nextTick(() => {
                    let cleanElement = () => {
                        let activeRipple = this.dialogElement.querySelector(".ripple.active");

                        if (activeRipple) {
                            activeRipple.classList.remove("active");
                        }

                        this.dialogInnerElement.removeEventListener(transitionEndEventName, cleanElement);
                        document.body.removeChild(this.dialogElement);
                        this.dialogTransform = "";
                    };

                    this.transitionOff = true;
                    this.dialogTransform = "";
                    this.calculateDialogPos(this.closeTo);

                    window.setTimeout(() => {
                        this.transitionOff = false;
                        this.active = false;
                        this.dialogInnerElement.addEventListener(transitionEndEventName, cleanElement);
                    });
                    this.closePopup();
                    this.$emit("close");
                });
            }
        }
    },
    components: {
        backdrop
    },
    mounted() {
        this.$nextTick(() => {
            this.dialogElement = this.$el;
            this.dialogInnerElement = this.$refs.dialog;
            this.removeDialog();
        });
    },
    beforeDestroy() {
        this.removeDialog();
    }
};
</script>

<style lang="scss" scoped>
.dialog-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column;
    flex-flow: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    pointer-events: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9996;
}

.dialog-container.active {
    pointer-events: auto;
}

.dialog-container.active .dialog {
    opacity: 1 !important;
    transform: scale(1) !important;
    max-width: 700px;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: opacity, transform;
}

.dialog-backdrop {
    position: fixed;
    z-index: 2;
}

.dialog {
    min-width: 280px;
    max-width: 700px;
    max-height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column;
    flex-flow: column;
    overflow: auto;
    position: relative;
    z-index: 3;
    outline: none;
    opacity: 0;
    transform: scale(0.9, 0.85);
    transform-origin: center center;
    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) 0.05s;
    will-change: opacity, transform;
}

.dialog.reference {
    transform-origin: top center;
}

.dialog.transition-off {
    transition: none !important;
}

.dialog p {
    margin: 0;
}

.dialog-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 24px 24px 0;
}

.dialog-content {
    padding: 0 24px 24px;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    overflow: auto;
    position: relative;
    background: linear-gradient(180deg, $white, $white 1px, transparent 0), linear-gradient(0deg, #fff, #fff 3px, transparent 0), linear-gradient(180deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12) 1px, transparent 0), linear-gradient(0deg, rgba(0, 0, 0, 0.2) 1px, rgba(0, 0, 0, 0.2) 2px, transparent 0);
    background-attachment: local, local, scroll, scroll;
}

.dialog-content:first-child {
    padding-top: 24px;
}

.dialog-content p:first-child:not(:only-child) {
    margin-top: 0;
}

.dialog-content p:last-child:not(:only-child) {
    margin-bottom: 0;
}

.dialog-body {
    margin: 0 -24px;
    padding: 0 24px;
    overflow: auto;
}

.dialog-actions {
    min-height: 52px;
    padding: 8px 24px 16px 24px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: relative;
}

.dialog-actions:before {
    height: 1px;
    position: absolute;
    top: -1px;
    right: 0;
    left: 0;
    background-color: $white;
    content: " ";
}

.dialog-actions .md-button {
    min-width: 64px;
    margin: 0;
    padding: 0 8px;
}

.dialog-actions .md-button + .md-button {
    margin-left: 8px;
}

.dialog-container .dialog {
    background-color: $white;
    padding: 16px;
    border-radius: $border-sm;
}

.dialog-container .dialog.no-spacing {
    padding: 0;
}

@media (max-width: 479px) {
    .dialog-container .dialog {
        width: calc(100% - 16px);
        min-width: calc(100% - 16px);
        max-width: calc(100% - 16px);
        max-height: calc(100% - 16px);
        margin: 8px;
    }
}
</style>
