<template>
    <div class="profile" :key="view" v-if="isUser">
        <cashback v-if="view === 'сashback'" />
        <order-list v-if="view === 'orders'" />
        <card-list v-if="view === 'cards'" />
        <referral v-if="view === 'referral'" />
        <settings v-if="view === 'settings'" />
        <discount v-if="view === 'discount'" />
        
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import orderList from "./profile/orders.vue";
import cardList from "./profile/cards";
import referral from "./profile/referral";
import settings from "./profile/settings";
import discount from "./profile/discount/index.vue";
import cashback from "./profile/cashback/index.vue";

export default {
    components: {
        orderList,
        cardList,
        referral,
        settings,
        discount,
        cashback
    },
    data() {
        return {
            view: "user"
        };
    },
    computed: {
        ...mapGetters(["isDesktop", "user"]),
        isUser() {
            return !!this.user;
        },
        userName() {
            return this.user.name || this.user.email;
        },
        viewName() {
            switch (this.view) {
                case "orders":
                    return "Мои заявки";
                case "cards":
                    return "Мои карты";
                case "referral":
                    return "Реферальная программа";
                case "cashback":
                    return "Кешбэк";
                case "settings":
                    return "Мой Профиль";
                case "discount":
                    return "Скидки";
                default:
                    return null;
            }
        }
    },
    watch: {
        "$route.params.view": {
            handler(view) {
                if (this.$route.name === "profile") {
                    let isPage = view === "orders" || view === "cards" || view === "referral" || view === "сashback" || view === "settings" || view === "discount";

                    if (typeof view != "undefined" && isPage) {
                        this.view = view;
                    } else {
                        this.$router.push({
                            name: "profile",
                            params: Object.assign({}, this.$route.params, {
                                view: "orders"
                            }),
                            query: Object.assign({}, this.$route.query)
                        });
                    }
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
.profile {
    display: flex;
    flex-direction: column;
}

.profile > .grid {
    width: 100%;
}

.profile-view {
    background-color: $white;
    margin: 16px;
    padding: 16px 8px;
    border-radius: 10px;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
}

.profile-view .view-name {
    color: $grafit;
    font-size: 26px;
    margin: 0 16px 24px 16px;
}

.profile-view .view-name.referral {
    margin: 0 16px 8px 16px;
}

.cards-view {
    margin-top: 1rem;
    padding: 0 8px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
}

.cards-view .view-name {
    color: $grafit;
    font-size: 26px;
    margin: 0 16px 24px 16px;
}

.orders-move,
.cards-move {
    transition: transform 0.64s;
}

.add-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    padding: 16px;
    border-radius: $border-sm;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.32);
}

.add-card .title {
    font-size: 18px;
    color: $grafit;
    margin-right: 16px;
}

.add-card .icon {
    cursor: pointer;
    color: $grafit;
}

.add-card .icon:hover {
    color: $orange;
}

.referral > .grid {
    width: 100%;
}

.referral > .grid > .cell {
    padding: 0;
}

.referral .description p {
    color: $grafit;
    font-size: 14px;
    line-height: 18px;
}

.referral .description p:not(:last-child) {
    margin-bottom: 16px;
}

.referral .referral-link-card,
.referral .promo-code-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px !important;
    border: 1px solid #ebeff6;
    border-radius: $border-sm;
}

.referral .referral-link-card > .title,
.referral .promo-code-wrap > .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
}

.referral .referral-link-card > .link {
    color: $black;
    font-size: 16px;
    font-weight: 400;
    background-color: #ebeff6;
    padding: 12px 16px;
    margin-bottom: 16px;
    border-radius: $border-sm;
}

.referral > .border {
    width: calc(100% - 32px);
    margin: 24px 16px;
}

.referral .referral-accruals > .title,
.referral .cash-back-accruals > .title {
    color: $grafit;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 32px;
}

.referral .referral-details,
.referral .cash-back-details {
    background-color: #ebeff6;
    padding: 12px 16px !important;
    border-radius: $border-sm;
}

hr {
    opacity: 0.5;
}

.referral .referral-details > .description,
.referral .cash-back-details > .description {
    color: $grafit;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
}

.referral .referral-details > .title,
.referral .cash-back-details > .title,
.referral .referral-details > .total,
.referral .cash-back-details > .total {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
}

.referral .referral-details > .total > .code,
.referral .cash-back-details > .total > .code {
    font-weight: 400;
}

.referral .referral-details > .history,
.referral .cash-back-details > .history {
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
}

.referral .promo-code-wrap {
    margin-top: 32px;
}

.referral .promo-code-wrap > .title {
    margin-bottom: 0;
}

.referral .access-denied {
    color: $grafit;
    font-size: 16px;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.referral .access-denied a {
    color: #fff;
}

.linkButton {
    margin-left: 1rem;
}

.referral .access-denied .user-name {
    margin-right: 0.5rem;
    font-weight: 500;
    font-size: 18px;
}

.settings .section-name {
    color: $grafit;
    font-size: 20px;
    margin: 16px;
}

.settings > .grid {
    width: 100%;
}

.settings > .grid > .cell {
    padding: 0 1%;
}

.settings > .grid > .cell:first-child {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-self: flex-start;
}

.settings > .grid > .cell:first-child > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
}

.settings .action {
    display: flex;
    flex-direction: row;
    margin: 24px 16px 16px 16px;
}

.email {
    color: $grafit;
    font-size: 16px;
}

.сhange-pass {
    box-shadow: -2px 0 2px -2px rgba(0, 0, 0, 0.16);
}

@media (max-width: 739px) {
    .сhange-pass {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.16);
    }
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container .card {
    position: relative;
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;
}

@media (max-width: 739px) {
    .container .card {
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }
}

.container .card:hover {
    transform: translateY(5px);
}

.container .card:before {
    content: "";
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.03);
    pointer-events: none;
    z-index: 1;
}

.circle svg {
    position: relative;
    width: 150px;
    height: 150px;
    z-index: 1000;
}

svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: $grey-lg-text;
    stroke-width: 10;
    stroke-linecap: round;
    transform: translate(5px, 5px);
}

svg circle:nth-child(2) {
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
}

.card:nth-child(1) svg circle:nth-child(2) {
    stroke-dashoffset: calc(440px - (440px * 100) / 100);
    stroke: $orange;
}

.card:nth-child(2) svg circle:nth-child(2) {
    stroke-dashoffset: calc(440 - (440 * 0) / 100);
    stroke: $green;
}

.card:nth-child(3) svg circle:nth-child(2) {
    stroke-dashoffset: calc(440 - (440 * 0) / 100);
    stroke: $blue;
}

.percent {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: inset 0 0 50px $page-wrap;
    z-index: 1;
    margin: 0 auto;
}

.percent .number {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.percent .number h2 {
    color: $grafit;
    font-weight: 600;
    font-size: 26px;
    transition: 0.5s;
}

.card:hover .percent .number h2 {
    color: $grafit;
    font-size: 30px;
}

.card:hover .percent img {
    width: 100px;
    transition: 0.5s;
}

.percent .number h2 span {
    font-size: 24px;
    color: $grafit;
}

.card:hover .percent .number h2 span {
    font-size: 24px;
    color: $grafit;
    transition: 0.5s;
}

.card:hover .percent .number h3 span {
    font-size: 24px;
    color: $grafit;
    transition: 0.5s;
}

.text {
    position: relative;
    color: $grey-text;
    margin-top: 1rem;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 1px;
    transition: 0.5s;
    max-width: 200px;
}

.card:hover .text {
    color: $grey-text;
}

.description-setting {
    background-color: #ebeff6;
    padding: 2px 16px !important;
    border-radius: 10px;
    text-align: start !important;
    line-height: 25px;
    margin-top: 1rem;
    display: block !important;
}

.description-setting div {
    margin: 10px 0;
}

.description-setting b {
    font-weight: 500;
}

.deleteAccBtn {
    margin-top: 2rem;
}

.deleteAccBtn {
    background-color: $red !important;
}

.deleteAccBtn:hover {
    background-color: $red !important;
}

.level-image {
    width: 90px;
    margin-top: 1rem;
    transition: 0.5s;
}
</style>
