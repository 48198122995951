<template>
    <div class="grid cashback-charts">
        <div class="info-block cell cell--6-col cell--12-col-tablet">
            <div>
                <vue-ellipse-progress :color="levelColor" :progress="levelProgress" empty-color-fill="#5656562e" emptyColor="#565656eb" :size="circleSize" :thickness="10" :empty-thickness="1" line="round" lineMode="out 3" :legendValue="2" :fontSize="circleFontSize" font-color="#3597fa">
                    <span> {{ user.level }}</span>
                    <span class="mr-1"> / </span>
                    <span> 3 </span>
                    <ul class="level-stars">
                        <li v-for="s in levelStars" :key="s">
                            <img src="../../../assets/star.png" />
                        </li>
                    </ul>
                </vue-ellipse-progress>
            </div>

            <div class="info-block_description">
                <h4>
                    {{ $t("settings.yourLevel") }}: <b> {{ getLevelName(user.level) }} - {{ getLevelPercent(user.level) }}% </b>
                </h4>

                <ul v-html="getInfoAboutLevelsHTML" />
            </div>
        </div>
        <div class="info-block  cell cell--6-col cell--12-col-tablet">
            <div>
                <vue-ellipse-progress :progress="bonusesProgress" :color="levelColor" font-color="#3597fa" empty-color="#ebeff6" :size="circleSize" :thickness="10" :empty-thickness="5" :legend-value="availableBonusesSum" animation="rs 3000" :fontSize="circleFontSize">
                    <template v-slot:default="{ counterTick: { currentValue } }">
                        <div class="price">
                            <span> {{ new Intl.NumberFormat("ru-RU").format(currentValue).trim() }}</span> из
                            <span> {{ new Intl.NumberFormat("ru-RU").format(allBonuses).trim() }}</span>
                        </div>
                    </template>
                </vue-ellipse-progress>
            </div>
            <div class="info-block_description">
                <h4 v-html="availableSumTextHtml" />
                <p v-html="levelProgressHtml.main" />
                <p v-if="levelProgressHtml.side" v-html="levelProgressHtml.side" />
            </div>
        </div>
        <div class="info-block cell cell--6-col cell--12-col-tablet">
            <div>
                <vue-ellipse-progress :progress="100" :color="levelColor" font-color="#3597fa" empty-color="#ebeff6" :size="circleSize" :thickness="10" :empty-thickness="5" :fontSize="circleFontSize">
                    <span> {{ getTotalExchangeSum.sum }} {{ getTotalExchangeSum.code }} </span>
                </vue-ellipse-progress>
            </div>
            <div class="info-block_description">
                <h4 v-html="getTotalExchangesSumHtml" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            // config
            circleSize: 120,
            circleFontSize: "0.9rem",

            levelColor: {
                radial: false,
                colors: [
                    {
                        color: "#6546f7",
                        offset: "0",
                        opacity: "1"
                    },
                    {
                        color: "lime",
                        offset: "100",
                        opacity: "0.6"
                    }
                ]
            },
            progress: 0,
            price: 0,
            colorSteps: {
                0: "GREEN",
                1: "BLUE",
                2: "RED"
            },
            description: "level",
            availableBonusesPrecent: null,
            nextLevelSum: null
        };
    },
    computed: {
        ...mapGetters(["user"]),
        currentLocale() {
            return this.$i18n.locale;
        },
        getLevelsPrices() {
            let prices = [
                {
                    from: 0,
                    to: 99999
                },
                {
                    from: 100000,
                    to: 499999
                },
                {
                    from: 500000,
                    to: Infinity
                }
            ];

            return prices;
        },
        getInfoAboutLevelsHTML() {
            let getLevelsPrices = this.getLevelsPrices;

            let result = "";

            getLevelsPrices.forEach((l, i) => {
                let levelCount = i + 1;

                let endRow = l.to == Infinity ? this.$t("settings.getInfoAboutLevelsHTML.andMore1") : this.$t("settings.getInfoAboutLevelsHTML.andMore2", { to: this.priceFormatting(l.to) });

                endRow += ` (${this.getLevelPercent(levelCount)}%)`

                let row = this.$t("settings.getInfoAboutLevelsHTML.startRow", { levelCount, levelName: this.getLevelName(levelCount), from: this.priceFormatting(l.from), endRow });
                result += row;
            });
            return result;
        },
        levelProgress() {
            return (100 / 3) * this.user.level;
        },
        levelStars() {
            let array = [];
            for (let p = 1; p <= this.user.level; p++) {
                array.push(p);
            }
            return array;
        },

        bonusesProgress() {
            if (this.user.verified && this.user.referral && this.user.accrual) {
                if (this.allBonuses === 0) return 0;

                let precent = this.allBonuses / 100;
                let progress = Math.floor(this.availableBonusesSum / precent);
                return progress;
            } else {
                return 0;
            }
        },
        allBonuses() {
            return this.user.verified && this.user.referral && this.user.accrual ? this.user.referral.balance + this.user.accrual.balance : 0;
        },
        availableBonusesSum() {
            let sum = this.user.verified && this.user.referral && this.user.accrual ? this.user.referral.balance + this.user.accrual.availableBalance : 0;
            return sum;
        },
        availableSumTextHtml() {
            return this.$t("settings.availableSumTextHtml", { sum: this.availableBonusesSum });
        },
        getTotalExchangesSumHtml() {
            let { sum } = this.getTotalExchangeSum;
            return this.$t("settings.getTotalExchangesSumHtml", { sum });
        },

        getTotalExchangeSum() {
            let totalExchangeInRub = this.user.totalExchanged;
            return { sum: totalExchangeInRub, code: "RUB" };
        },
        levelProgressHtml() {
            let main = "";
            let side = "";
            let userLevel = this.user.level;

            if (userLevel === 1) {
                let sumForNextLevel = this.getLevelsPrices[0].to + 1 - this.getTotalExchangeSum.sum;
                sumForNextLevel = this.priceFormatting(Math.floor(sumForNextLevel));

                main = this.$t("settings.levelProgressHtml.main", { nextLevelSum: sumForNextLevel, nextLevelName: this.getLevelName(2) });
                side = this.$t("settings.levelProgressHtml.side", { coef: 2, newNum: 70, oldNum: 35 });
            } else if (userLevel === 2) {
                let sumForNextLevel = this.getLevelsPrices[1].to + 1 - +this.getTotalExchangeSum.sum;
                sumForNextLevel = this.priceFormatting(Math.floor(sumForNextLevel));

                main = this.$t("settings.levelProgressHtml.main", { nextLevelSum: sumForNextLevel, nextLevelName: this.getLevelName(3) });
                side = this.$t("settings.levelProgressHtml.side", { coef: 1.5, newNum: 105, oldNum: 70 });
            } else if (userLevel === 3) {
                main = this.$t("settings.levelProgressHtml.current", { levelName: this.getLevelName(3) });
            }
            return {
                main,
                side
            };
        }
    },
    methods: {
        priceFormatting(amount) {
            return new Intl.NumberFormat(this.currentLocale == "ru" ? "ru-RU" : "en-EN").format(amount).trim();
        },
        getLevelPercent(level) {
            switch (level) {
                case 1:
                    return 0.1;
                case 2:
                    return 0.2;
                case 3:
                    return 0.3;
                default:
                    return 0;
            }
        },
        getLevelName(n) {
            switch (n) {
                case 1:
                    return this.$t("settings.getLevelName.starter");
                case 2:
                    return this.$t("settings.getLevelName.medium");
                case 3:
                    return this.$t("settings.getLevelName.pro");
                default:
                    throw new Error("Incorrect level");
            }
        },
        determineColor(value) {
            let allB = this.allBonuses;
            let precent = allB / 100;
            let availablePrecents = Math.floor(value / precent);

            let index = 0;
            for (const step of [33, 66, 99]) {
                if (availablePrecents > step) {
                    index += 1;
                } else {
                    this.priceColor = this.colorSteps[index];
                    return this.colorSteps[index];
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.cashback-charts .info-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
}

.cashback-charts .info-block_description {
    background-color: #ebeff6;
    padding: 10px 16px !important;
    border-radius: $border-sm;
    text-align: start !important;
    line-height: 25px;
    display: block !important;
    flex-grow: 1;
    margin: 0px 20px;
    color: #565656;
    width: min-content;
}

.cashback-charts .info-block_description h4 {
    font-size: 16px;
    margin-bottom: 10px;
}

.cashback-charts .info-block_description h4 b {
    font-weight: 600;
}

.cashback-charts .info-block_description ul {
    font-size: 14px;
}

.cashback-charts .info-block_description li,
.cashback-charts .info-block_description p {
    margin: 2px 0px;
    font-size: 14px;
}

.cashback-charts .info-block_description li b,
.cashback-charts .info-block_description p b {
    font-weight: 600;
    font-size: 14px;
}
</style>
