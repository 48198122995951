import axios from "axios";

export default {
    state: {
        content: {
            news: [],
            faq: [],
            notification: "",
            agreement: "",
            contacts: "",
            "agreement-ak": "",
            ad: ""
        }
    },
    actions: {
        async fetchContent({ commit }) {
            try {
                const response = await axios.get("content");
                commit("SET_CONTENT", response.data);
            } catch (e) {
                console.error(e);
            }
        }
    },
    mutations: {
        SET_CONTENT(state, content) {
            state.content = content;
        }
    },
    getters: {
        getAd(state) {
            return state.content.ad;
        },
        news(state) {
            return state.content.news;
        },
        faqs(state) {
            return state.content.faq;
        },
        notification(state) {
            return state.content.notification;
        },
        agreement(state) {
            return state.content.agreement;
        },
        amlkyc(state) {
            return state.content["agreement-ak"];
        },
        contacts(state) {
            return state.content.contacts;
        }
    }
};
