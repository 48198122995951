import Vue from "vue";
import App from "./app.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import Extensions from "../plugins/extensions";
import FastClick from "fastclick";
import SocketIO from "../plugins/socket-io";
import { mapGetters, mapMutations } from "vuex";
import VueEllipseProgress from "vue-ellipse-progress";
import eventer from "./event";
import { i18n } from "./i18n";
import Toast from "vue-toastification";
import { URL } from "./config.js";
import VueCountdown from "@chenfengyuan/vue-countdown";
import "vue-toastification/dist/index.css";
import { getStorageValue, setStorageValue } from "./utils/localStorageManage.js";
// import './styles/global.scss'

import "./assets/styles/styles.scss";

Vue.config.productionTip = false;
Vue.prototype.URL = URL;
Vue.prototype.eventer = eventer;
Vue.component(VueCountdown.name, VueCountdown);

// Подключение к сокету на клиенте:
Vue.use(
    new SocketIO({
        debug: false,
        connection: URL.server,
        reconnection: true,
        vuex: {
            store,
            actionPrefix: "SOCKET_"
        }
    })
);

Vue.use(VueEllipseProgress);

Vue.use(Extensions);
Vue.use(Toast, {});

axios.defaults.baseURL = URL.api;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["scode"] = "E";

///
axios.interceptors.response.use(
    res => {
        return res;
    },
    error => {
        let status = error?.response?.status;
        if (status === 403) {
            store.commit("LOGOUT_USER");
            store.commit("SET_SNACK_BAR", { active: true, text: i18n.t("notify.authExpire") });
        }
        return Promise.reject(error);
    }
);
axios.interceptors.request.use(
    request => {
        request.headers.common["token"] = localStorage.getItem("token") || null;
        let locale = i18n.locale == "ru" ? "rus" : "eng";
        request.headers.common["locale"] = locale;
        return request;
    },
    e => {
        return Promise.reject(e);
    }
);

FastClick.attach(document.body);

Vue.mixin({
    methods: {
        arrayEmpty: arr => !(Array.isArray(arr) && arr.length),
        objectEmpty: obj => Object.keys(obj).length === 0 && obj.constructor === Object
    }
});

Vue.directive("click-outside", {
    bind: (el, binding) => {
        const bubble = binding.modifiers.bubble;
        const handler = e => {
            if (bubble || (!el.contains(e.target) && el !== e.target)) {
                binding.value(e);
            }
        };
        el.__vueClickOutside__ = handler;
        document.addEventListener("click", handler);
    },
    unbind: el => {
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
    }
});

export default new Vue({
    el: "#app",
    router,
    i18n,
    store,
    // components: {
    //     AdNotify
    // },
    render: h => h(App),
    computed: {
        ...mapGetters(["order", "orderToken"]),
        isDesktop() {
            return this.$store.state.ui.isDesktop;
        },
        isAuthenticated() {
            return this.$store.state.profile.isAuthenticated;
        },
        token() {
            return this.$store.state.profile.token;
        },
        referralLink() {
            return this.$route.query.ref;
        },
        currentOrder() {
            return this.order;
        }
    },

    watch: {
        isAuthenticated() {
            if (this.isAuthenticated) {
                this.socketAuthenticate();
                this.$store.dispatch("getUser");
                this.SET_ORDER_TOKEN(null);
            } else {
                this.$socket.emit("signout");
            }
        }
    },
    async created() {
        // let query = this.$route.query;

        // let lang = query.lang;

        // console.log({query})

        // if (query.cur_from && query.cur_to) {

        // setInterval(() => {
        //     let chatElement = document.querySelectorAll(" .modal-logo__inner");
        //     console.log(chatElement, "chatElement");
        // }, [1000]);

        this.$store.dispatch("fetchContent");
        // this.$store.dispatch("fetchCourse");

        this.$store.dispatch("setReferralLink", this.referralLink);

        if (this.isAuthenticated) {
            this.$store.dispatch("getUser");
        }

        let visited = getStorageValue("visited");

        if (!visited) {
            this.SET_SNACK_BAR({
                active: true,
                text: this.$t("cookieNotifyText")
            });
            setStorageValue("visited", true);
        }

        this.initSocketListeners();
    },
    mounted() {
        this.$nextTick(function() {
            window.addEventListener("resize", this.updateWindowWidth);
            this.updateWindowWidth();
        });
    },
    sockets: {
        connect() {
            if (this.isAuthenticated) {
                this.socketAuthenticate();
            }
        }
    },
    methods: {
        ...mapMutations(["SET_ORDER_TOKEN", "SET_SNACK_BAR"]),

        updateWindowWidth() {
            let windowWidth = document.documentElement.clientWidth;

            if (windowWidth >= 814 && this.isDesktop === false) {
                this.$store.dispatch("setScreen", true);
            }
            if (windowWidth < 814 && this.isDesktop === true) {
                this.$store.dispatch("setScreen", false);
            }
        },

        initSocketListeners() {
            this.sockets.subscribe("update user", user => {
                this.$store.commit("SET_USER", user);
            });

            this.sockets.subscribe("update order", order => {
                this.$store.commit("UPDATE_ORDER", order);
            });
        },
        socketAuthenticate() {
            this.$socket.emit("authenticate", this.$store.state.profile.token);
        }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.updateWindowWidth);
    }
});
