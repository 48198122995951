const CARD_STATUSES = {
    VERIFY_CARD: "verify-card",
    BANNED_CARD: "banned-card",
    PROCESS_CARD: "process-card",
    RESOLVED_CARD: "resolved-card",
    REJECTED_CARD: "rejected-card",
    // REJECTED_ORDER_CARD: "rejected-order-card",
    ADD_CARD: "add-card"
};

export { CARD_STATUSES };
