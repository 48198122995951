export default function inputTake(amount) {
    let a = +amount;

    if (!a) {
        a = 0;
        this.amount.take = a;
    }


    this.calculateGive(a);

    this.lastInputActive = "take";
}
