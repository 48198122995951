<template>
    <div class="buttons-wrapper">
        <div class="spacer"></div>
        <my-button class="outline" :outline="true" :spinner="spinner1" :value="text1" @click.native="func1"></my-button>
        <my-button :spinner="spinner2" :value="text2" @click.native="func2"></my-button>
    </div>
</template>

<script>
import myButton from "../ui/button";

export default {
    props: {
        text1: String,
        text2: String,
        func1: Function,
        func2: Function,
        spinner1: Boolean,
        spinner2: Boolean
    },
    components: {
        myButton
    }
};
</script>

<style lang="scss" scoped>
.buttons-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}
.buttons-wrapper .button {
    padding: 8px 16px;
}
.buttons-wrapper .button.outline {
    padding: 7px 16px;
    margin-right: 16px;
}
</style>
