import getPriceExchangeCurrencyInRub from "../../../utils/getPriceExchangeCurrencyInRub";
import sumWithPrecision from "../../../utils/sumWithPrecision";

export default function getCommissionBTC(amountTake, amountGive, direction) {
    const exchange = this.exchange;
    const giveCurrency = exchange.currency.give;
    const takeCurrency = exchange.currency.take;
    let than = this;

    if (!takeCurrency.BTCFeeEnabled) {
        this.commissionInfo = "";
        this.minExc = exchange.limit.min;
        return 0;
    }

    let isGiveCurrencyBTC = giveCurrency.code == "BTC" && giveCurrency.name == "Bitcoin";
    if (!isGiveCurrencyBTC) {
        this.commissionInfo = "";
        return 0;
    }

    const commissionList = giveCurrency.commissionBTC;

    let sumInRUB;

    if (direction == "take") {
        sumInRUB = getPriceExchangeCurrencyInRub(exchange, amountTake, "take");
    } else {
        sumInRUB = getPriceExchangeCurrencyInRub(exchange, amountGive, "give", true);
    }

    let firstComm = commissionList[0];
    const takePrecision = takeCurrency.precision;

    if (firstComm) {
        let oneTake = getPriceExchangeCurrencyInRub(exchange, 1, "take");
        let fromSum = sumWithPrecision({ precision: takePrecision, sum: firstComm.from / oneTake });

        let minTake = exchange.limit.min;
        // console.log({ minTake, fromSum });
        if (fromSum < minTake) {
            this.minExc = fromSum;
        } else {
            this.minExc = null;
        }
    } else {
        this.minExc = null;
    }

    let result = findPriceCommissionAndSet(exchange, commissionList, sumInRUB);

    // console.log({ result });
    return result;

    function findPriceCommissionAndSet(exchange, commissionList, sumInRub) {
        // console.log({ commissionList });

        // console.log({ sumInRub });

        let findedCommission = commissionList.find(r => sumInRub >= r.from && r.to >= sumInRub);

        // console.log({ findedCommission });

        if (findedCommission) {
            return setCommissionTextHtml(findedCommission, exchange);
        } else {
            than.commissionInfo = "";
            return 0;
        }
    }

    function setCommissionTextHtml(com, exchange) {
        const currencyTake = exchange.currency.take;
        const code = currencyTake.code;
        const commission = com.sum;
        const fromRub = com.from;
        const toRub = com.to;

        const precision = currencyTake.precision;
        let oneTakeRubs = getPriceExchangeCurrencyInRub(exchange, 1, "take");
        let fromSum = sumWithPrecision({ precision, sum: fromRub / oneTakeRubs });
        let toSum = sumWithPrecision({ precision, sum: toRub / oneTakeRubs });

        let minTake = than.limit.min;
        // console.log({ fromSum, toSum, minTake });
        if (toSum > minTake) {
            let commissionInfo = than.$t("exchanger.getCommissionBTC.commissionInfo", { fromSum, toSum, code, commission });
            than.commissionInfo = commissionInfo;
            return commission;
        } else {
            return 0;
        }
    }
}

// export default function getCommissionBTC(amountTake, amountGive, action) {
//     const exchange = this.exchange;

//     const conversionInRub = (amount, type, withFormula = false) => {
//         let formula = exchange.rate.formula;

//         if (type === "take") {
//             let result;
//             const takeCode = exchange.currency.take.code;
//             const takeValue = exchange.currency.take.evalue;

//             if (takeCode === "RUB") {
//                 result = Math.floor(+amount * this.precision.take) / this.precision.take;
//             } else if (takeCode === "EUR" || takeCode === "USD") {
//                 result = Math.floor(+amount * takeValue * this.precision.take) / this.precision.take;
//             } else {
//                 result = Math.floor(+amount * takeValue * this.precision.take) / this.precision.take;
//             }

//             if (withFormula) {
//                 result = Math.floor(+result * formula * this.precision.take) / this.precision.take;
//             }

//             return result;
//         } else {
//             const giveValue = exchange.currency.give.evalue;
//             let result = Math.floor(giveValue * +amount * this.precision.give) / this.precision.give;
//             if (withFormula) {
//                 result = this.getExchangeAmount(exchange, "take", amount, true);
//             }
//             return result;
//         }
//     };

//     const resetCommissionInfo = () => {
//         this.commissionInfo = "";
//     };

//     const setCommissionInfo = range => {
//         let commission = range.sum;
//         let oneTake = conversionInRub(1, "take");
//         let fromSum = Math.floor((range.from / oneTake) * this.precision.take) / this.precision.take;
//         let toSum = Math.floor((range.to / oneTake) * this.precision.take) / this.precision.take;
//         let code = exchange.currency.take.code;
//         let minTake = this.limit.min;
//         if (toSum > minTake) {
//             this.commissionInfo = `При обмене от <b style="font-weight: 500">${fromSum} ${code}</b> до <b style="font-weight: 500">${toSum} ${code}</b> взымается доп. комиссия ${commission} BTC.`;
//             return commission;
//         } else {
//             return 0;
//         }
//     };

//     if (exchange.currency.take.isCash || exchange.currency.take.uniqueType == "qr") {
//         this.minExc = exchange.limit.min;
//         return 0;
//     }

//     if (this.exchange.currency.give.code !== "BTC") {
//         resetCommissionInfo();
//         return 0;
//     }

//     const ranges = this.exchange.currency.give.commissionBTC;

//     if (action === "take") {
//         const takeRub = conversionInRub(amountTake, "take");
//         // console.log(takeRub, "take in rub");

//         let firstRange = ranges[0];
//         if (firstRange) {
//             let oneTake = conversionInRub(1, "take");
//             let fromSum = Math.floor((firstRange.from / oneTake) * this.precision.take) / this.precision.take;
//             // console.log(fromSum, "from sum");
//             let minTake = exchange.limit.min;
//             if (fromSum < minTake) {
//                 this.minExc = fromSum;
//             } else {
//                 this.minExc = null;
//             }
//         } else {
//             this.minExc = null;
//         }
//         let findRange = ranges.find(r => takeRub >= r.from && r.to >= takeRub);
//         // console.log(findRange, "findRange");

//         if (findRange) {
//             return setCommissionInfo(findRange);
//         } else {
//             resetCommissionInfo();
//             return 0;
//         }
//     } else {
//         const giveRub = conversionInRub(amountGive, "give", true);

//         let firstRange = ranges[0];
//         if (firstRange) {
//             let oneTake = conversionInRub(1, "take");
//             let fromSum = Math.floor((firstRange.from / oneTake) * this.precision.take) / this.precision.take;
//             console.log(fromSum, "from sum");
//             let minTake = exchange.limit.min;
//             if (fromSum < minTake) {
//                 this.minExc = fromSum;
//             } else {
//                 this.minExc = null;
//             }
//         } else {
//             this.minExc = null;
//         }
//         // console.log(amountTake, "amountTake");
//         // console.log(giveRub, "giveRub");
//         let findRange = ranges.find(r => giveRub >= r.from && r.to >= giveRub);
//         // console.log(findRange, "findRange");
//         if (findRange) {
//             return setCommissionInfo(findRange);
//         } else {
//             resetCommissionInfo();
//             return 0;
//         }
//     }
// }
