<template>
    <footer class="footer-wrap">
        <div class="footer-content">
            <div class="best-logo">
                <a :href="link" target="_blank">
                    <img src="../assets/best.png" :title="$t('footer.title')" :alt="$t('footer.alt')" style="" width="auto" height="31" border="0" />
                </a>
            </div>
            <div class="copyright">© {{ currentYear }}, Ex-Bank.cc - {{ $t("footer.copyright") }}</div>
        </div>
    </footer>
</template>

<script>
import CONSTANT_LINKS from "../constants/links.json";

export default {
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
        link() {
            let locale = this.$i18n.locale === "ru" ? "rus" : "eng";
            return CONSTANT_LINKS.bestchange[locale];
        }
    }
};
</script>

<style lang="scss" scoped>
.footer-wrap {
    width: 100%;
    height: 48px;
}

.footer-content {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    max-width: 1200px;
    padding: 0 16px;
    margin: 0 auto;
    overflow: hidden;
}

.footer-content .best-logo {
    margin-right: 16px;
}

.footer-content .copyright {
    color: $grafit;
    font-size: 14px;
    line-height: 20px;
}

.footer-content .social {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-content .social > .icon {
    cursor: pointer;
    color: $grafit;
}

.footer-content .social > .icon:not(:last-child) {
    margin-right: 16px;
}

@media (max-width: 749px) {
    .footer-wrap {
        height: 120px;
    }

    .footer-content {
        flex-direction: column;
        padding: 8px;
    }

    .footer-content .best-logo {
        margin: 8px 0 16px 0;
    }

    .footer-content .copyright {
        text-align: center;
        margin-bottom: 16px;
    }
}
</style>
