<template>
    <div class="auth-wrapper" v-click-outside="closeAuth">
        <div class="icon-close" @click="closeAuth">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
        </div>

        <div class="auth-form">
            <div class="header-name">{{ headerName }}</div>

            <form novalidate @submit.stop.prevent="send">
                <my-input v-model.trim="name" :label="$t('auth.form.name')" :validation="validations.name" v-if="view === 'signUp'"></my-input>

                <my-input class="white" v-model.trim="email" label="E-mail" inputType="email" name="email" @input="formatEmail" :validation="validations.email" v-if="view !== 'confirm'"></my-input>

                <my-input class="white" v-model.trim="password" :label="$t('auth.form.password')" inputType="password" :validation="validations.password" v-if="view !== 'resetPassword' && view !== 'confirm'"></my-input>

                <my-input v-model.trim="code" :label="$t('auth.form.code')" :validation="validations.code" @input="formatCode" v-if="view === 'confirm'"></my-input>
                <Captcha link="captchaSignup" v-if="view === 'signUp' || view === 'signIn'" ref="captcha" />
                <agree v-model="agree" v-if="view === 'signUp'"></agree>

                <my-button class="full-width" :value="buttonName" :spinner="spinner" :disabled="!agree"></my-button>
            </form>

            <div class="actions-wrapper" v-if="view === 'signIn'">
                <div class="spacer"></div>
                <div class="reset-password" @click.stop="changeView('resetPassword')">
                    {{ $t("auth.forgotPassword") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import input from "./ui/input.vue";
import button from "./ui/button.vue";
import agree from "./agree.vue";

import Captcha from "./Captcha.vue";

export default {
    components: {
        myInput: input,
        myButton: button,
        agree: agree,
        Captcha
    },
    props: {
        view: {
            type: String,
            default: "signIn"
        }
    },
    data() {
        return {
            name: "",
            email: "",
            password: "",
            code: "",
            agree: true,
            validations: {
                name: {
                    isValid: true,
                    text: ""
                },
                email: {
                    isValid: true,
                    text: ""
                },
                password: {
                    isValid: true,
                    text: ""
                },
                code: {
                    isValid: true,
                    text: ""
                }
            },
            spinner: false
        };
    },
    computed: {
        ...mapGetters(["referralLink"]),
        headerName() {
            switch (this.view) {
                case "signIn":
                    return this.$t("auth.headerName.myProfile");
                case "signUp":
                    return this.$t("auth.headerName.reg");
                case "resetPassword":
                    return this.$t("auth.headerName.resetPassword");
                case "confirm":
                    return this.$t("auth.headerName.confirm");
                default:
                    return null;
            }
        },
        buttonName() {
            switch (this.view) {
                case "signIn":
                    return this.$t("auth.submit.logIn");
                case "signUp":
                    return this.$t("auth.submit.reg");
                case "resetPassword":
                    return this.$t("auth.submit.send");
                case "confirm":
                    return this.$t("auth.submit.confirm");
                default:
                    return null;
            }
        }
    },
    watch: {
        name() {
            if (this.name) {
                this.validations.name.isValid = true;
            }
        },
        email() {
            if (this.email) {
                this.validations.email.isValid = true;
            }
        },
        password() {
            if (this.password) {
                this.validations.password.isValid = true;
            }
        },
        code() {
            if (this.code) {
                this.validations.code.isValid = true;
            }
        }
    },
    methods: {
        ...mapActions(["userSignup", "userSignin", "userResetPassword", "userConfirmResetPassword"]),
        closeAuth() {
            this.$emit("close-auth");
        },
        changeView(view) {
            this.$emit("change-view", view);
        },
        formatEmail(email) {
            this.email = email.toLowerCase();
        },
        formatCode(code) {
            this.code = code.toLowerCase();
        },
        validateEmail(email) {
            const re = /\S+@\S+\.\S+/;
            return re.test(email);
        },
        send() {
            if (!this.spinner) {
                this.spinner = true;
                switch (this.view) {
                    case "signIn":
                        this.signIn();
                        break;
                    case "signUp":
                        this.signUp();
                        break;
                    case "resetPassword":
                        this.resetPassword();
                        break;
                    case "confirm":
                        this.confirm();
                        break;
                }
            }
        },
        successHandler() {
            this.closeAuth();
            this.$router.push({
                name: "profile",
                params: { view: "orders" }
            });
            this.email = "";
            this.password = "";
            this.spinner = false;
            this.name = "";
        },
        errorHandler(data) {
            if (data.email) {
                this.validations.email.text = data.error;
                this.validations.email.isValid = false;
            } else {
                this.validations.password.text = data.error;
                this.validations.password.isValid = false;
            }
            this.spinner = false;
        },
        signUp() {
            let isValidCaptcha = this.$refs.captcha.validateCaptcha();
            if (this.name.length === 0) {
                this.validations.name.isValid = false;
                this.validations.name.text = this.$t("auth.validation.reg.name");

                this.spinner = false;
            } else if (!this.validateEmail(this.email)) {
                this.validations.email.isValid = false;
                this.validations.email.text = this.$t("auth.validation.reg.email");

                this.spinner = false;
            } else if (this.password.length < 6) {
                this.validations.password.isValid = false;
                this.validations.password.text = this.$t("auth.validation.reg.password");
                this.spinner = false;
            } else if (!isValidCaptcha) {
                this.spinner = false;
            } else {
                let data = {
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    referralLink: this.referralLink
                };

                this.userSignup({ data, successHandler: this.successHandler, errorHandler: this.errorHandler });
            }
        },
        signIn() {
            let isValidCaptcha = this.$refs.captcha.validateCaptcha();
            if (!this.validateEmail(this.email)) {
                this.validations.email.isValid = false;
                this.validations.email.text = this.$t("auth.validation.login.email");
                this.spinner = false;
            } else if (this.password.length === 0) {
                this.validations.password.isValid = false;
                this.validations.password.text = this.$t("auth.validation.login.password");
                this.spinner = false;
            } else if (!isValidCaptcha) {
                this.spinner = false;
            } else {
                let data = { email: this.email, password: this.password };
                this.userSignin({ data, successHandler: this.successHandler, errorHandler: this.errorHandler });
            }
        },
        resetPassword() {
            if (!this.validateEmail(this.email)) {
                this.validations.email.isValid = false;
                this.validations.email.text = this.$t("auth.validation.recover.email");
                this.spinner = false;
            } else {
                const successHandler = () => {
                    this.spinner = false;
                    this.changeView("confirm");
                };

                const errorHandler = message => {
                    this.validations.email.isValid = false;
                    this.validations.email.text = message;
                    this.spinner = false;
                };

                this.userResetPassword({ email: this.email, successHandler, errorHandler });
            }
        },
        confirm() {
            if (this.code.length === 0) {
                this.validations.code.isValid = false;
                this.validations.code.text = this.$t("auth.validation.recover.code");
                this.spinner = false;
            } else {
                const successHandler = () => {
                    this.code = "";
                    this.spinner = false;
                    this.changeView("signIn");
                };

                const errorHandler = message => {
                    this.validations.code.isValid = false;
                    this.validations.code.text = message;
                    this.spinner = false;
                };

                let data = {
                    email: this.email,
                    code: this.code
                };

                this.userConfirmResetPassword({ data, successHandler, errorHandler });
            }
        }
    }
};
</script>
<i18n>
    {
      "ru": {
        "hello": "hello world!"
      },
      "en": {
        "hello": "hello world!"
      },

    }
    </i18n>

<style lang="scss" scoped>
.auth-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 0;
    top: 0;
    right: 0;
    background-color: #ffffff;
    transition: width 0.32s;
    z-index: 999;
    box-shadow: -2px 0 2px -2px rgba(0, 0, 0, 0.16);
    overflow-x: hidden;
    overflow-y: auto;
}
.auth-form {
    padding: 24px 32px;
}

.header-name {
    color: $grafit;
    font-size: 28px;
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
}

.icon-close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 24px;
    color: $black;
}

.white {
    color: $white;
}

.button {
    margin: 8px 0 0 0 !important;
}

.actions-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color: #5c83e6;
    font-size: 14px;
    margin: 24px 0 8px 0;
}

.signUp-link {
    color: $code;
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
}

.reset-password {
    color: $grey-lg-text;
    cursor: pointer;
}

.signUp-link,
.reset-password {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.signUp-link:hover,
.reset-password:hover {
    color: $black;
    transition: 0.5s;
}

.checkbox {
    margin: 24px 0 16px 0 !important;
}

@media (max-width: 479px) {
    .auth-wrapper {
        min-width: 0;
    }
}
</style>
