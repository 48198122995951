<template lang="">
    <div class="rejectedCardComment">
        <span>{{ $t("popupCard.rejectedCard.error1") }}</span
        ><br />

        <div v-if="comment" class="rejectedCardComment_text">{{ $t("popupCard.rejectedCard.commentText") }} "{{ comment }}"</div>
    </div>
</template>
<script>
export default {
    props: {
        comment: String
    }
};
</script>
<style lang="scss" scoped>
.rejectedCardComment {
    font-size: 14px;
    line-height: 20px;
    color: red;
    text-align: center;
    margin-top: 10px;
}

.rejectedCardComment_text {
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
}
</style>
