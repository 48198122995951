const VueExtensions = {
    install(Vue) {
        Vue.mixin({
            methods: {
                arrayEmpty: arr => !(Array.isArray(arr) && arr.length),
                objectEmpty: obj => Object.keys(obj).length === 0 /*&& obj.constructor === Object*/,
                showSnackBar(text) {
                    this.$store.commit("changeSnackBar", { active: true, text: text });
                }
            }
        });

        Vue.directive("scroll", {
            inserted: function(el, binding) {
                let f = function(evt) {
                    if (binding.value(evt, el)) {
                        window.removeEventListener("scroll", f);
                    }
                };
                window.addEventListener("scroll", f);
            }
        });
    }
};

export default VueExtensions;
