<template lang="">
    <VueModal :bg-overlay="'#00000052'" :defaultWidth="'fit-content'" :bgPanel="'white'" :visible="show" @hideModal="closeModal" :animation-panel="'modal-slide-bottom'">
        <div v-if="hasCard">
            <AddCard v-if="isAdd" :closeHandler="closeModal" :setCard="c => (this.card = c)" />
            <ProcessCard v-if="isProcessing" :closeHandler="closeModal" :number="card.number" />
            <ResolvedCard v-if="isResolved" :closeHandler="closeModal" :number="card.number" />
            <BannedCard v-if="isBanned" :closeHandler="closeModal" :number="card.number" />
            <RejectedCard v-if="isRejected" :closeHandler="closeModal" :card="card" />
        </div>
    </VueModal>
</template>
<script>
// Libs
import VueModal from "../vue-modal/vue-modaltor.vue";

// Components
import AddCard from "./AddCard.vue";
import ProcessCard from "./ProcessCard.vue";
import ResolvedCard from "./ResolvedCard.vue";
import BannedCard from "./BannedCard.vue";
import RejectedCard from "./RejectedCard.vue";

// Constatns
import { CARD_STATUSES } from "../../constants/card/cardStatuses";

export default {
    data() {
        return {
            card: null
        };
    },
    props: {
        show: Boolean,
        closeModal: Function,
        cardData: Object
    },
    components: {
        VueModal,
        AddCard,
        ProcessCard,
        ResolvedCard,
        BannedCard,
        RejectedCard
    },
    computed: {
        hasCard() {
            return !!this.card;
        },
        status() {
            let status = this.card.status;
            switch (status) {
                case 100:
                    return CARD_STATUSES.VERIFY_CARD;
                case 101:
                    return CARD_STATUSES.PROCESS_CARD;
                case 102:
                    return CARD_STATUSES.RESOLVED_CARD;
                case 103:
                    return CARD_STATUSES.REJECTED_CARD;
                case 104:
                    return CARD_STATUSES.BANNED_CARD;
                default:
                    return CARD_STATUSES.ADD_CARD;
            }
        },
        isVerification() {
            return this.status === CARD_STATUSES.VERIFY_CARD;
        },
        isProcessing() {
            return this.status === CARD_STATUSES.PROCESS_CARD;
        },
        isResolved() {
            return this.status === CARD_STATUSES.RESOLVED_CARD;
        },
        isRejected() {
            return this.status === CARD_STATUSES.REJECTED_CARD;
        },
        isBanned() {
            return this.status === CARD_STATUSES.BANNED_CARD;
        },
        isAdd() {
            return this.status === CARD_STATUSES.ADD_CARD;
        }
    },
    watch: {
        show() {
            if (this.show) {
                this.sockets.subscribe("update card", upd => {
                    let currenctCardId = this?.card?._id;
                    if (currenctCardId !== upd._id) return;
                    this.card = Object.assign({}, upd);
                });
            } else {
                this.sockets.unsubscribe("update card");
            }
        },

        cardData() {
            if (this.cardData) {
                if (this.card) {
                    if (this.card._id !== this.cardData._id) {
                        this.card = this.cardData;
                    }
                } else {
                    this.card = this.cardData;
                }
            }
        }
    }
};
</script>
<style lang=""></style>
