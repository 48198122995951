<template>
    <div class="select__net" v-click-outside="close">
        <div class="select__net-selected" @click="toggle" >
            {{ transformNetName(currency.netCode) }}
        </div>
        <div v-if="isOpen" class="select__net-options">
            <div v-for="currency in currencies" :key="currency._id" @click="change(currency)" class="select__net-options__item">
                <p>{{ transformNetName(currency.netCode) }}</p>
            </div>
        </div>
    </div>
</template>
<script>
// Utils
import transformNetName from "../../utils/transformNetName";

export default {
    data() {
        return {
            isOpen: false
        };
    },
    props: {
        currencies: Array,
        currency: Object,
        selectCurrency: Function
    },
    methods: {
        transformNetName: transformNetName,
        toggle() {
            this.isOpen = !this.isOpen;
        },
        close() {
            this.isOpen = false;
        },
        change(option) {
            this.selectCurrency(option);
            this.close();
        }
    }
};
</script>
<style lang="scss">
.select__net {
    // border-radius: 5px;
    // background-color: rgba(128, 128, 128, 0.15);
    // padding: 3px 5px;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    margin-left: 5px;
}
.select__net-selected {
    border-radius: 5px;
    background-color: #e9e7e7;
    padding: 2px 4px;
}

.select__net-options {
    max-width: max-content;
    position: absolute;
    top: 0;
    border-radius: 5px;
    background-color: #e9e7e7;
    overflow: hidden;
    // padding: 3px 5px;
}

.select__net-options__item {
    min-width: max-content;
    padding: 2px 4px;
    margin-bottom: 2px;
    width: 100%;
    
    transition: all 0.2s;
}

.select__net-options__item:hover {
    // min-width: max-content;
    // padding: 2px 4px;
    // margin-bottom: 2px;
    background-color: #3597fa57;
}


</style>
