<template>
    <transition name="spinner" appear>
        <div class="spinner indeterminate">
            <svg class="spinner-draw" viewBox="25 25 50 50">
                <circle :class="{ 'spinner-path': true, white: color == 'white', blue: color == 'blue' }" cx="50" cy="50" r="20" stroke-width="2" />
            </svg>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "white"
        }
    }
};
</script>

<style lang="scss" scoped>
.spinner {
    display: block;
    width: 30px;
    height: 30px;
    position: relative;
    margin: 0 auto;
    pointer-events: none;
    will-change: transform, opacity;
}

.spinner.indeterminate .spinner-draw {
    animation: spinner-rotate 1.9s linear infinite;
    transform: rotate(0deg) translateZ(0);
}

.spinner.indeterminate .spinner-path {
    stroke-dasharray: 2, 200;
    animation: spinner-dash 1.425s ease-in-out infinite;
}

.spinner.spinner-leave-active {
    opacity: 0;
    transform: scale(0.8) translateZ(0);
}

.spinner:not(.indeterminate).spinner-enter-active {
    transition-duration: 2s;
}

.spinner:not(.indeterminate).spinner-enter-active .spinner-draw {
    animation: spinner-initial-rotate 1.98s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
}

.spinner-draw {
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: rotate(270deg) translateZ(0);
    transform-origin: center center;
    will-change: transform, opacity;
}

.spinner-path {
    fill: none;
    stroke-dashoffset: 0;
    stroke-miterlimit: 10;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@keyframes spinner-rotate {
    to {
        transform: rotate(1turn) translateZ(0);
    }
}

@keyframes spinner-initial-rotate {
    0% {
        opacity: 0;
        transform: rotate(-90deg) translateZ(0);
    }
    20% {
        opacity: 1;
    }
    to {
        transform: rotate(270deg) translateZ(0);
    }
}

@keyframes spinner-dash {
    0% {
        stroke-dasharray: 2, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    to {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

.button .spinner .spinner-path.white {
    stroke: $white;
}
.button .spinner .spinner-path.blue {
    stroke: #3597fa;
}
</style>
