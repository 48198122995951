import Mixin from './mixin';
import Logger from './logger';
import Listener from './listener';
import Emitter from './emitter';
import SocketIO from 'socket.io-client';

export default class VueSocketIO {

    /**
     * lets take all resource
     * @param io
     * @param vuex
     * @param debug
     */
    constructor({connection, reconnection, vuex, debug}){

        Logger.debug = debug;
        this.io = this.connect(connection, reconnection);
        this.emitter = new Emitter(vuex);
        this.listener = new Listener(this.io, this.emitter);

    }

    /**
     * Vuejs entrypoint
     * @param Vue
     */
    install(Vue){

        Vue.prototype.$socket = this.io;
        Vue.prototype.$vueSocketIo = this;
        Vue.mixin(Mixin);

        Logger.info('Vue-Socket.io plugin enabled');

    }


    /**
     * registering socketio instance
     * @param connection
     */
    connect(connection, reconnection){

        if(connection && typeof connection === 'object'){

            Logger.info('Received socket.io-client instance');

            return connection;

        } else if(typeof connection === 'string'){

            Logger.info('Received connection string');

            return this.io = SocketIO(connection, {
                reconnection: reconnection
            });

        } else {

            throw new Error('Unsupported connection type');

        }

    }

}
