export default function getCommissionQiwi() {
    let phone = this.mobileNumber.replace(/\D+/g, "");

    if (!this.exchange) {
        this.qiwiNotification = "";
        return;
    }
    if (this.exchange.currency.give.uniqueType != "qiwi" || phone.length < 2) {
        this.qiwiNotification = "";
        return 0;
    }

    let codes = phone.slice(0, 2);
    if (codes === "79") {
        this.qiwiNotification = "";
        return 0;
    } else {
        this.qiwiNotification = this.$t("exchanger.getCommissionQiwi.qiwiNotification");
        return 0.01;
    }
}
