import filterData from "../../../lists/currenciesFilter.json";

export default function giveFilterList() {
    let currencies = this.allCurrenciesGive;

    if (currencies.length == 0) return [];

    let filter = {
        rub: false,
        usd: false,
        eur: false,
        coin: false,
        ps: false,
        fiat: false
    };
    currencies.forEach(c => {
        filter[c.type] = true;
    });

    return filterData.filter(i => filter[i.id]);
}
