import { render, staticRenderFns } from "./referral.vue?vue&type=template&id=bdd7a992&scoped=true&"
import script from "./referral.vue?vue&type=script&lang=js&"
export * from "./referral.vue?vue&type=script&lang=js&"
import style0 from "./referral.vue?vue&type=style&index=0&id=bdd7a992&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdd7a992",
  null
  
)

export default component.exports