<template lang="">
    <div class="orderPaymentSum">
        <span class="orderPaymentSum_name">{{ $t("orderPage.payment.sum") + ":" }}</span>
        <span class="orderPaymentSum_value"
            ><span>{{ " " + sum }}</span> <CopyBoard :text="sum + ''"
        /></span>
    </div>
</template>
<script>
import CopyBoard from "../../../../components/ui/CopyBoard.vue";

export default {
    props: {
        sum: Number
    },
    components: {
        CopyBoard
    }
};
</script>
<style lang="scss" scoped>
.orderPaymentSum {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-left: 16px;
}

.orderPaymentSum_name {
    font-size: 18px;
}
.orderPaymentSum_value {
    font-weight: 600;
    display: flex;
    align-items: center;
    word-break: break-all;
    font-size: 22px;
}

.orderPaymentSum_value span {
    margin-right: 5px;
}
</style>
