<template lang="">
    <div class="orderFields">
        <div v-for="field in fields" :key="field._id" class="orderFields_item">
            <span class="orderFields_itemName">{{ field.name + ": " }}</span>
            <span class="orderFields_itemValue"
                ><span>{{ field.value }}</span> <CopyBoard :text="field.value"
            /></span>
        </div>
    </div>
</template>
<script>
import CopyBoard from "../../../../components/ui/CopyBoard.vue";

export default {
    props: {
        fields: Array
    },
    components: {
        CopyBoard
    }
};
</script>
<style lang="scss" scoped>
.orderFields {
    margin: 20px 0px;
}

.orderFields_item {
    margin-bottom: 10px;
    // display: flex;
    // align-items: center;
    // flex-wrap: wrap;
}

.orderFields_itemName {
}
.orderFields_itemValue {
    font-weight: 600;
    display: flex;
    align-items: center;
    word-break: break-all;
}
.orderFields_itemValue span {
    margin-right: 5px;
}
</style>
