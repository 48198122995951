<template lang="">
    <div class="orderPaymentLinks">
        <div v-for="(link, i) in links" :key="i" class="orderPaymentLinks_item">
            <span> {{ $t("popupOrder.paymentOrder.paymentMethodLink.title") }} </span>
            <a target="_blank" referrerpolicy="origin" :href="link">
                {{ $t("popupOrder.paymentOrder.paymentMethodLink.btn") }}
            </a>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        links: Array
    }
};
</script>
<style lang="scss" scoped>
.orderPaymentLinks {
}
.orderPaymentLinks_item {
    margin-bottom: 10px;
}
.orderPaymentLinks_item a {
    cursor: pointer;
    color: #3597fa;
    text-transform: uppercase;
}
.orderPaymentLinks_item a:hover {
    text-decoration: underline;
}
</style>
