<template>
    <div class="contacts">
        <div class="card">
            <div class="page-name">
                {{ $t("contacts.title") }}
            </div>
            <div class="options">
                <div class="option">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock">
                            <circle cx="12" cy="12" r="10"></circle>
                            <polyline points="12 6 12 12 16 14"></polyline>
                        </svg>
                    </div>
                    <div class="info">
                        <div class="title">
                            {{ $t("contacts.workingTime") }}
                        </div>
                        <div class="sub-title">
                            {{ $t("contacts.aroundTheClock") }}
                        </div>
                    </div>
                </div>

                <div class="option">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail">
                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                            <polyline points="22,6 12,13 2,6"></polyline>
                        </svg>
                    </div>
                    <div class="info">
                        <div class="title">{{ $t("contacts.emailSupport") }}:</div>
                        <address class=" open-link">
                            <a target="_blank" href="mailto:support@ex-bank.cc"> support@ex-bank.cc</a>
                        </address>
                    </div>
                </div>
                <div class="option">
                    <div class="icon">
                        <img alt="" class="chat-img" src="https://img.icons8.com/ios/50/000000/macbook-chat--v3.png" />
                    </div>
                    <div class="info">
                        <div class="title">{{ $t("contacts.onlineChat") }}:</div>
                        <div @click="openChat" class=" open-link">
                            {{ $t("contacts.openChat") }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="description">
                {{ $t("contacts.help") }}
            </div>

            <div class="border"></div>

            <div class="page-name">
                {{ $t("contacts.serviceReviews") }}
            </div>
            <div class="links-wrap">
                <p class="answer" v-html="content"></p>
                <p class="description" v-html="$t('contacts.conclusion')">
                    <!-- {{)}} -->
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(["contacts"]),
        content() {
            let locale = this.$i18n.locale;

            let result = locale == "ru" ? this.contacts.rus : this.contacts.eng;

            return result;
        }
    },
    methods: {
        openChat() {
            // window.jivo_api.open();
            window.Chatra("openChat");
            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
.contacts {
    color: $black;
    font-size: 16px;
}

.contacts .card {
    background-color: $white;
    margin: 16px;
    padding: 24px 32px;
    border-radius: $border-sm;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
}

.contacts .card .page-name {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 32px;
    display: flex;
    color: $grafit;
    text-align: center;
}

.options {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
}

.options .option {
    display: flex;
    flex-direction: row;
    padding: 16px 0;
    border-radius: 10px;
    width: -webkit-fill-available;
    margin: 8px 10px;
}

//.options .option:not(:last-child) {
//    margin-right: 64px;
//}

.options .option .icon {
    color: #565656;
    margin-right: 16px;
    display: flex;
    align-items: center;
}

.options .option .info > .title {
    color: $grafit;
    font-size: 18px;
    margin-bottom: 8px;
}

.options .option .info > .sub-title {
    font-size: 16px;
    color: $grey-lg-text;
}

.description {
    color: $grafit;
    line-height: 26px;
}

.border {
    height: 2px;
    width: 100%;
    margin: 15px 0;
}

.links-wrap > p:not(:last-child) {
    margin-bottom: 16px;
}

.links-wrap > p:nth-child(9) {
    margin-bottom: 32px;
}

.links-wrap > p:last-child {
    line-height: 26px;
}

.links-wrap > p > a {
    color: #0081ff;
    text-decoration: underline;
}

.links-wrap > p > a:hover {
    color: #2a5885;
    text-decoration: underline;
}

.monitoring-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
    align-items: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 16px;
}

.monitoring-wrap > a {
    width: 88px;
    height: 32px;
}

.monitoring-wrap > a:not(:last-child) {
    margin-right: 16px;
}

.monitoring-wrap > a > img {
    width: 100%;
    height: 100%;
}

@media (max-width: 739px) {
    .contacts .card {
        margin: 8px;
        padding: 24px 16px;
    }
    .options {
        flex-direction: column;
    }

    .border {
        margin: 10px 0;
    }
}

.open-link {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    color: $link;
    transition: all 0.32s;

    &:hover {
        color: #2a5885;
    }
}

.chat-img {
    width: 28px;
}
</style>
