import ExchangeService from "../../../services/ExchangeService";

export default async function() {
    try {
        this.SET_LOADING(true);
        let response = await ExchangeService.getAll();
        let exchanges = response.data;
        this.exchanges = exchanges;
        this.setTakeFilterList();
    } catch (e) {
        console.error("Ошибка загрузки пар!", e);
    } finally {
        this.SET_LOADING(false);
    }
}
