<template>
    <div class="cashback-description grid">
        <div class="cell cell--9-col cell--12-col-tablet ">
            <p>
                {{ $t("cashbackDesc.p1") }}
            </p>
            <p>
                {{ $t("cashbackDesc.p2") }}
            </p>
            <p>
                {{ $t("cashbackDesc.p3") }}
            </p>
        </div>
    </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.cashback-description p {
    color: $grafit;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
}
</style>
