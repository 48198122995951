<template lang="">
    <div class="cardStep">
        <div class="cardStep_icon">{{ number }}</div>
        {{ text }}
    </div>
</template>
<script>
export default {
    props: {
        text: String,
        number: Number
    }
};
</script>
<style lang="scss" scoped>
.cardStep_icon {
    display: inline-block;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
    color: $white;
    background-color: $orange;
    border-radius: 50%;
    margin-right: 8px;
}
</style>
