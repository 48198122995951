import axios from "axios";

export default async function() {
    try {
        const response = await axios.get("service/break");
        // console.log(response.data, "break");
        this.technicalBreak = response.data;
    } catch (e) {
        console.error(e);
    }
}
