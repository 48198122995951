export default function rateUpdate() {
    if (!this.exchange) {
        this.amount = {
            take: 0,
            give: 0
        };
        return;
    }

    if (this.lastInputActive === "take") {
        this.calculateGive(+this.amount.take);
    } else {
        this.calculateTake(+this.amount.give);
    }
}
