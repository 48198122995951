<template lang="">
    <div>
        <ModalContentWrap :headerName="$t('popupCard.bannedCard.title', { number })" :closeHandler="closeHandler">
            <div class="bannedCardSection" v-html="$t('popupCard.bannedCard.desc')" />
        </ModalContentWrap>
    </div>
</template>
<script>
// Components
import ModalContentWrap from "../ModalContentWrap/ModalContentWrap.vue";
export default {
    components: {
        ModalContentWrap
    },
    props: {
        closeHandler: Function,
        number: String
    }
};
</script>
<style lang="scss" scoped>
.bannedCardSection {
    font-size: 16px;
    line-height: 22px;
    color: #252525;
}
</style>
