import OrderService from "../../../services/OrderService";

export default async function() {
    try {
        this.spinner = true;
        let data = {
            email: this.email === "" ? null : this.email,
            commissionQiwi: !!this.getCommissionQiwi,
            promocode: this.promocodeInfo.code || null,
            mobile: this.mobileNumber === "" ? null : this.mobileNumber,
            referralLink: this.referralLink,
            referrer: document.referrer.indexOf("localhost") === -1 ? document.referrer : "",
            exchange: this.exchange._id,
            take: this.amount.take,
            give: this.amount.give,
            fields: {
                take: this.fields.take,
                give: this.fields.give
            },
            fieldsStamp: this.fieldsStamp || undefined
        };

        let response = await OrderService.create(data);

        let resData = response.data;

        if (resData.user) {
            this.USER_LOGIN(resData);
        }

        if (resData.orderToken) {
            this.SET_ORDER_TOKEN(resData.orderToken);
        }

        let order = resData.order;

        if (order) {
            let changeAmountOrder = order?.change?.amount;
            if (changeAmountOrder) {
                this.orderFieldsData = data;
                this.orderData = {
                    status: "change-amount",
                    order: order
                };
                this.showPreorder = true;
            } else {
                this.$router.push("/order/" + order.hash);
            }
        } else {
            let orderData = {};
            if (resData.card) {
                // let statusName = "";

                if (resData.status == 100) {
                    orderData = {
                        status: "verify",
                        card: {
                            status: resData.status
                        }
                    };
                } else if (resData.status == 103) {
                    orderData = {
                        status: "reverify",
                        card: {
                            status: resData.status,
                            comment: resData.comment,
                            number: resData.number
                        }
                    };
                }
            } else if (resData.qr) {
                orderData.status = "qr";
            }
            this.orderFieldsData = data;
            this.orderData = orderData;
            this.showPreorder = true;
        }
    } catch (err) {
        if (err.response && err.response.data && err.response.data.fields) {
            this.setFieldsError(err.response.data.errors);
        } else {
            let errorMessage = err?.response?.data?.error;

            if (errorMessage) {
                this.SET_SNACK_BAR({ active: true, text: errorMessage });
            }
        }
    } finally {
        this.spinner = false;
    }
}
