<template>
    <div class="cell cell--12-col cell--12-col-tablet cell--12-col-phone accrual-item grid">
        <div class="cell cell--2-col cell--2-col-tablet cell--2-col-phone amount">{{ new Intl.NumberFormat("ru-RU").format(accrual.sum).trim() }} <span class="code">RUB</span></div>
        <div class="referral" v-if="!isReferral">{{ promocodeText }}</div>

        <div class="cell cell--10-col cell--10-col-tablet cell--10-col-phone exchange">
            <div class="pair">
                {{ accrual.date }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        accrual: Object,
        mode: {
            type: String,
            default: "referral"
        }
    },
    computed: {
        isReferral() {
            return this.mode === "referral";
        },
        promocodeText() {
            if (this.accrual.mode === "promocode") {
                return this.accrual.text;
            } else {
                return `${this.$t("accrualCard.text")} ${this.accrual.order}.`;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.accrual-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $grafit;
}

.amount {
    font-size: 16px;
    font-weight: 500;
}

.amount > .code {
    font-weight: 400;
}

.pair {
    display: flex;
}
</style>
