<template lang="">
    <ModalContentWrap :headerName="$t('popupCard.rejectedCard.title', { number: card.number })" :closeHandler="closeHandler">
        <div class="rejectedOrderCardSection">
            <StepItem :number="1" :text="$t('popupCard.rejectedCard.step1')" />

            <ContentDescription :number="number" />
            <ContentComment :comment="card.comment" />

            <CardPhotoExample v-if="!image" />
            <StepItem :number="2" :text="addImageText" />
            <ImageSelect :image="image" :file="file" :removeImage="removeImage" :isScreenMobile="isScreenMobile" :setImage="setImage" :setFile="setFile" />
        </div>
        <template v-slot:btns>
            <div class="rejectedOrderCardSection_btnsWrap">
                <my-button class="outline" :outline="true" :value="$t('popupCard.rejectedCard.confirm.cancel')" @click.native="closeHandler"></my-button>
                <my-button :value="$t('popupCard.rejectedCard.confirm.send')" @click.native="sendVerifyHandler" :spinner="spinner" />
            </div>
        </template>
    </ModalContentWrap>
</template>
<script>
import { mapGetters } from "vuex";

// Components
import ModalContentWrap from "../../ModalContentWrap/ModalContentWrap.vue";
import ImageSelect from "../../ui/ImageSelect.vue";
import CardPhotoExample from "../UI/CardPhotoExample.vue";

// // Computeds
import addImageText from "../computeds/addImageText";
import isScreenMobile from "../computeds/isScreenMobile";

// // Service
import OrderService from "../../../services/OrderService";

// // UI
import StepItem from "../../ui/StepItem.vue";
import MyButton from "../../ui/button.vue";

// Modules
import ContentDescription from "./ContentDescription";
import ContentComment from "./ContentComment";
// import NoVerifyExchange from "../modules/NoVerifyExchange.vue";

export default {
    components: {
        ModalContentWrap,
        ImageSelect,
        CardPhotoExample,
        StepItem,
        MyButton,
        ContentDescription,
        ContentComment
        // NoVerifyExchange
    },
    data() {
        return {
            image: null,
            file: null,
            spinner: false
        };
    },
    props: {
        number: Number,
        closeHandler: Function,
        card: Object,
        successCreateHandler: Function,
        errorCreateHandler: Function,
        orderFieldsData: Object
    },
    computed: {
        ...mapGetters(["isAuthenticated"]),
        addImageText,
        isScreenMobile,
        comment() {
            let text = this.card?.comment?.client;
            return text;
        }
    },
    methods: {
        async sendVerifyHandler() {
            try {
                if (this.spinner) return;
                if (!this.file) return;
                this.spinner = true;
                let form = new FormData();
                form.append("data", JSON.stringify(this.orderFieldsData));
                form.append("dest", "card");
                form.append("image", this.file);
                let response = await OrderService.create(form);
                this.successCreateHandler(response.data);
            } catch (e) {
                console.error(e);
                this.errorCreateHandler(e);
            } finally {
                this.spinner = false;
            }
        },
        removeImage() {
            this.file = null;
            this.image = null;
        },
        setImage(i) {
            this.image = i;
        },
        setFile(f) {
            this.file = f;
        }
    },
    beforeDestroy() {
        this.image = null;
        this.file = null;
        this.spinner = false;
    }
};
</script>
<style lang="scss" scoped>
.rejectedOrderCardSection_btnsWrap {
    display: flex !important;
    justify-content: center;
}

.rejectedOrderCardSection_btnsWrap button:first-child {
    margin-right: 10px;
    display: inline-block;
}

.rejectedOrderCardSection_btnsWrap button:last-child {
    margin-left: 10px;
}
</style>
