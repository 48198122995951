import axios from "axios";

class DiscountService {
    getData() {
        return axios.get("/discount/get-data");
    }
    getStatistics() {
        return axios.get("/discount/statistics");
    }
}

const discountService = new DiscountService();

export default discountService;
