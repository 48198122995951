<template lang="">
    <div class="imageSelect">
        <div v-if="!image" class="imageSelect_addWrap">
            <input type="file" id="image" :accept="isScreenMobile ? 'image/*' : ''" capture="camera" @change="changeImage" />
            <label for="image">{{ isScreenMobile ? $t("popupCard.addCard.addCardBtn.create") : $t("popupCard.addCard.addCardBtn.attach") }} </label>
        </div>
        <div class="imageSelect_imageWrap" v-if="image">
            <img alt="" class="imageSelect_image" :src="image" />
            <div class="imageSelect_imageRemove" :title="$t('popupCard.addCard.deleteImageBtn')" @click="removeImage">
                <svg style="margin-top: 2px" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="-3 1 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        image: String,
        file: Object,
        removeImage: Function,
        isScreenMobile: Boolean,
        setImage: Function,
        setFile: Function
    },
    methods: {
        changeImage(e) {
            let than = this;
            const files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }
            let size = files[0].size;
            if (size <= 16777216) {
                checkImage(files[0]);
            }
            function checkImage(file) {
                const reader = new FileReader();
                reader.onloadend = e => {
                    const arr = new Uint8Array(e.target.result).subarray(0, 4);
                    let header = "";
                    let isImage = false;
                    for (let i = 0; i < arr.length; i++) {
                        header += arr[i].toString(16);
                    }
                    switch (header) {
                        case "89504e47":
                            isImage = true;
                            break;
                        case "ffd8ffe0":
                        case "ffd8ffe1":
                        case "ffd8ffe2":
                        case "ffd8ffe3":
                        case "ffd8ffe8":
                        case "ffd8ffdb":
                        case "ffd8ffed":
                            isImage = true;
                            break;
                        default:
                            isImage = false;
                            break;
                    }
                    if (isImage) {
                        // console.log(than, 'than')
                        than.setFile(file);
                        createImage(file);
                    } else {
                        this.error = "Изображение должно иметь расширение .jpg, .jpeg, .png";
                    }
                };
                reader.readAsArrayBuffer(file);
            }
            function createImage(file) {
                const reader = new FileReader();
                reader.onload = e => {
                    than.setImage(e.target.result);
                };
                reader.readAsDataURL(file);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.imageSelect {

}

.imageSelect_addWrap {
    margin: 16px 0;
}

.imageSelect_addWrap .file {
    display: none;
}

.imageSelect #image {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

 .imageSelect #image + label {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $grafit;
    padding: 10px 16px 14px 16px;
    border: 1px solid $orange;
    border-radius: 8px;
}

.imageSelect #image:focus + label,
#image + label:hover {
    color: $orange;
}
.imageSelect_imageWrap {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
}

.imageSelect_image {
    max-width: 100%;
    max-height: 200px;
}

.imageSelect_imageRemove {
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 8px;
    right: 8px;
    line-height: 24px;
    color: #2a5885;
    background-color: $orange;
    border: 1.5px solid $grafit;
    border-radius: 50%;
}
</style>
