<template>
    <div class="profile-view">
        <div class="view-name" :class="'referral'">{{ $t("settings.title") }} ({{ user.email }})</div>

        <div class="grid">
            <div class="cell cell--4-col cell--12-col-tablet settings-col">
                <div>
                    <div class="section-name">
                        {{ $t("settings.global.title") }}
                    </div>

                    <my-input v-model.trim="name" :label="$t('settings.global.form.name')" :validation="validations.name"></my-input>

                    <my-input v-model.trim="mobile" :label="$t('settings.global.form.phone')" inputType="tel" :validation="validations.mobile"></my-input>
                </div>

                <my-button class="full-width" :value="$t('settings.global.form.saveBtn')" :spinner="spinner.updateUser" @click.native="updateUser"></my-button>
            </div>

            <div class="cell cell--4-col cell--12-col-tablet settings-col">
                <div>
                    <div class="section-name">
                        {{ $t("settings.password.title") }}
                    </div>
                    <my-input v-model.trim="password.old" inputType="password" :label="$t('settings.password.form.oldPassword')" :validation="validations.password.old"></my-input>

                    <my-input v-model.trim="password.new.first" inputType="password" :label="$t('settings.password.form.newPassword')" :validation="validations.password.new.first"></my-input>

                    <my-input v-model.trim="password.new.second" inputType="password" :label="$t('settings.password.form.newPasswordConfirm')" :validation="validations.password.new.second"></my-input>
                </div>

                <my-button class="full-width" :value="$t('settings.password.form.saveBtn')" :spinner="spinner.updatePassword" @click.native="changePassword"></my-button>
            </div>
            <div class="cell cell--4-col cell--12-col-tablet other-col">
                <div>
                    <div v-if="!user.verified">
                        <div class="user-email_section-desc">
                            {{ $t("settings.resendEmailDesc") }}
                        </div>
                        <my-button class="full-width resend-mail-btn" :value="$t('settings.resendEmailBtn')" :spinner="reverifySubmit" @click.native="resendVerifyMail" />
                    </div>
                </div>
                <my-button class="deleteAccBtn full-width" :value="$t('settings.deleteAccountBtn')" :spinner="spinner.deleteAccount" @click.native="deleteAccount"></my-button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions, mapMutations } from "vuex";
import myButton from "../../components/ui/button.vue";
import myInput from "../../components/ui/input";
import UserService from "../../services/UserService";

export default {
    components: {
        myButton,
        myInput
    },
    data() {
        return {
            reverifySubmit: false,
            levelColor: {
                radial: false,
                colors: [
                    {
                        color: "#6546f7",
                        offset: "0",
                        opacity: "1"
                    },
                    {
                        color: "lime",
                        offset: "100",
                        opacity: "0.6"
                    }
                ]
            },
            progress: 0,
            price: 0,
            colorSteps: {
                0: "GREEN",
                1: "BLUE",
                2: "RED"
            },
            description: "level",
            partner: null,
            availableBonusesPrecent: null,
            nextLevelSum: null,
            spinner: {
                updateUser: false,
                updatePassword: false,
                deleteAccount: false
            },
            name: "",
            mobile: "",
            password: {
                old: "",
                new: {
                    first: "",
                    second: ""
                }
            },
            validations: {
                name: {
                    isValid: true,
                    text: ""
                },
                mobile: {
                    isValid: true,
                    text: ""
                },
                password: {
                    old: {
                        isValid: true,
                        text: ""
                    },
                    new: {
                        first: {
                            isValid: true,
                            text: ""
                        },
                        second: {
                            isValid: true,
                            text: ""
                        }
                    }
                }
            }
        };
    },
    computed: {
        ...mapGetters(["user", "getCourse"]),
        currentLocale() {
            return this.$i18n.locale;
        },
        getLevelsPrices() {
            let prices = [
                {
                    from: 0,
                    to: 99999
                },
                {
                    from: 100000,
                    to: 499999
                },
                {
                    from: 500000,
                    to: Infinity
                }
            ];

            return prices;
        },
        getInfoAboutLevelsHTML() {
            let getLevelsPrices = this.getLevelsPrices;

            let result = "";

            getLevelsPrices.forEach((l, i) => {
                let levelCount = i + 1;

                let endRow = l.to == Infinity ? this.$t("settings.getInfoAboutLevelsHTML.andMore1") : this.$t("settings.getInfoAboutLevelsHTML.andMore2", { to: this.priceFormatting(l.to) });

                let row = this.$t("settings.getInfoAboutLevelsHTML.startRow", { levelCount, levelName: this.getLevelName(levelCount), from: this.priceFormatting(l.from), endRow });
                result += row;
            });
            return result;
        },
        levelProgress() {
            return (100 / 3) * this.user.level;
        },
        levelStars() {
            let array = [];
            for (let p = 1; p <= this.user.level; p++) {
                array.push(p);
            }
            return array;
        },

        bonusesProgress() {
            if (this.user.verified && this.user.referral && this.user.accrual) {
                if (this.allBonuses === 0) return 0;

                let precent = this.allBonuses / 100;
                let progress = Math.floor(this.availableBonusesSum / precent);
                return progress;
            } else {
                return 0;
            }
        },
        allBonuses() {
            return this.user.verified && this.user.referral && this.user.accrual ? this.user.referral.balance + this.user.accrual.balance : 0;
        },
        availableBonusesSum() {
            let sum = this.user.verified && this.user.referral && this.user.accrual ? this.user.referral.balance + this.user.accrual.availableBalance : 0;
            return sum;
        },
        availableSumTextHtml() {
            return this.$t("settings.availableSumTextHtml", { sum: this.availableBonusesSum });
        },
        getTotalExchangesSumHtml() {
            let { sum } = this.getTotalExchangeSum;
            return this.$t("settings.getTotalExchangesSumHtml", { sum });
        },

        getTotalExchangeSum() {
            let totalExchangeInRub = this.user.totalExchanged;
            return { sum: totalExchangeInRub, code: "RUB" };
        },
        levelProgressHtml() {
            let main = "";
            let side = "";
            let userLevel = this.user.level;

            if (userLevel === 1) {
                let sumForNextLevel = this.getLevelsPrices[0].to + 1 - this.getTotalExchangeSum.sum;
                sumForNextLevel = this.priceFormatting(Math.floor(sumForNextLevel));

                main = this.$t("settings.levelProgressHtml.main", { nextLevelSum: sumForNextLevel, nextLevelName: this.getLevelName(2) });
                side = this.$t("settings.levelProgressHtml.side", { coef: 2, newNum: 70, oldNum: 35 });
            } else if (userLevel === 2) {
                let sumForNextLevel = this.getLevelsPrices[1].to + 1 - +this.getTotalExchangeSum.sum;
                sumForNextLevel = this.priceFormatting(Math.floor(sumForNextLevel));

                main = this.$t("settings.levelProgressHtml.main", { nextLevelSum: sumForNextLevel, nextLevelName: this.getLevelName(3) });
                side = this.$t("settings.levelProgressHtml.side", { coef: 1.5, newNum: 105, oldNum: 70 });
            } else if (userLevel === 3) {
                main = this.$t("settings.levelProgressHtml.current", { levelName: this.getLevelName(3) });
            }
            return {
                main,
                side
            };
        }
    },
    mounted() {
        this.getPartner();
    },
    created() {
        if (this.user && (!this.name || !this.mobile)) {
            this.name = this.name || this.user.name || "";
            this.mobile = this.mobile || this.user.mobile || "";
        }
    },
    watch: {
        name() {
            if (this.name) {
                this.validations.name.isValid = true;
            }
        },
        mobile() {
            if (this.mobile) {
                this.validations.mobile.isValid = true;
                let x = this.mobile.replace(/\D/g, "").match(/(\d?)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
                this.mobile = !x[3] ? "+" + x[1] + " " + x[2] : "+" + x[1] + " " + "(" + x[2] + ") " + x[3] + (x[4] ? "-" + x[4] : "") + (x[5] ? "-" + x[5] : "");
            }
        },
        "password.old"() {
            if (this.password.old) {
                this.validations.password.old.isValid = true;
            }
        },
        "password.new.first"() {
            if (this.password.new.first) {
                this.validations.password.new.first.isValid = true;
            }
        },
        "password.new.second"() {
            if (this.password.new.second) {
                this.validations.password.new.second.isValid = true;
            }
        }
    },
    methods: {
        ...mapActions(["deleteUser", "userChangePassword", "userChange"]),
        ...mapMutations(["SET_SNACK_BAR"]),
        priceFormatting(amount) {
            return new Intl.NumberFormat(this.currentLocale == "ru" ? "ru-RU" : "en-EN").format(amount).trim();
        },
        getLevelName(n) {
            switch (n) {
                case 1:
                    return this.$t("settings.getLevelName.starter");
                case 2:
                    return this.$t("settings.getLevelName.medium");
                case 3:
                    return this.$t("settings.getLevelName.pro");
                default:
                    throw new Error("Incorrect level");
            }
        },
        resendVerifyMail() {
            if (this.reverifySubmit) return;

            this.reverifySubmit = true;

            UserService.resendVerify()
                .then(() => {
                    this.SET_SNACK_BAR({ active: true, text: this.$t("settings.sendEmailVerify") });
                })
                .catch()
                .finally(() => {
                    this.reverifySubmit = false;
                });
        },
        determineColor(value) {
            let allB = this.allBonuses;
            let precent = allB / 100;
            let availablePrecents = Math.floor(value / precent);

            let index = 0;
            for (const step of [33, 66, 99]) {
                if (availablePrecents > step) {
                    index += 1;
                } else {
                    this.priceColor = this.colorSteps[index];
                    return this.colorSteps[index];
                }
            }
        },
        async getPartner() {
            if (this.user.partner) {
                await axios
                    .get("partner")
                    .then(response => {
                        this.partner = response.data;
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        },
        updateUser() {
            if (!this.spinner.updateUser) {
                if (this.mobile && this.mobile.length <= 10) {
                    this.validations.mobile.isValid = false;
                    this.validations.mobile.text = this.$t("settings.changeUserPhoneError");
                } else {
                    this.spinner.updateUser = true;
                    const successHandler = () => {
                        this.spinner.updateUser = false;
                    };

                    const errorHandler = () => {
                        this.spinner.updateUser = false;
                    };

                    let data = {
                        name: this.name,
                        mobile: this.mobile
                    };

                    this.userChange({ data, successHandler, errorHandler });
                }
            }
        },
        changePassword() {
            if (!this.spinner.updatePassword) {
                this.validations.password = {
                    old: {
                        isValid: true,
                        text: ""
                    },
                    new: {
                        first: {
                            isValid: true,
                            text: ""
                        },
                        second: {
                            isValid: true,
                            text: ""
                        }
                    }
                };

                this.spinner.updatePassword = true;
                if (this.password.old.length === 0) {
                    this.validations.password.old.isValid = false;
                    this.validations.password.old.text = this.$t("settings.changePwdHandler.enterPassword");
                    this.spinner.updatePassword = false;
                } else if (this.password.new.first.length < 6) {
                    this.validations.password.new.first.isValid = false;
                    this.validations.password.new.first.text = this.$t("settings.changePwdHandler.minLengthError");
                    this.spinner.updatePassword = false;
                } else if (this.password.new.second.length === 0 || this.password.new.second.length < 6) {
                    this.validations.password.new.second.isValid = false;
                    this.validations.password.new.second.text = this.$t("settings.changePwdHandler.minLengthError");
                    this.spinner.updatePassword = false;
                } else if (this.password.new.first !== this.password.new.second) {
                    this.password.new.second = "";
                    this.validations.password.new.first.isValid = false;
                    this.validations.password.new.first.text = this.$t("settings.changePwdHandler.dontMatchError");
                    this.spinner.updatePassword = false;
                } else {
                    const successHandler = () => {
                        this.password.old = "";
                        this.password.new.first = "";
                        this.password.new.second = "";
                        this.spinner.updatePassword = false;
                    };

                    const errorHandler = data => {
                        if (data.password) {
                            this.validations.password.old.isValid = false;
                            this.validations.password.old.text = data.error;
                        }
                        this.spinner.updatePassword = false;
                    };

                    let data = {
                        old: this.password.old,
                        new: this.password.new.first
                    };

                    this.userChangePassword({ data, successHandler, errorHandler });
                }
            }
        },
        deleteAccount() {
            if (confirm(this.$t("settings.deleteAccountHandler"))) {
                this.deleteUser();
                this.$router.push("/");
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.profile {
    display: flex;
    flex-direction: column;
}

.profile > .grid {
    width: 100%;
}

.profile-view {
    background-color: $white;
    margin: 16px;
    padding: 16px 8px;
    border-radius: $border-sm;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

.profile-view .view-name {
    color: $grafit;
    font-size: 26px;
    margin: 0 16px 24px 16px;
}

.profile-view .view-name.referral {
    margin: 0 16px 8px 16px;
}

.cards-view {
    margin-top: 1rem;
    padding: 0 8px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
}

.cards-view .view-name {
    color: $grafit;
    font-size: 26px;
    margin: 0 16px 24px 16px;
}

.orders-move,
.cards-move {
    transition: transform 0.64s;
}

.add-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    padding: 16px;
    border-radius: $border-sm;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.32);
}

.add-card .title {
    font-size: 18px;
    color: $grafit;
    margin-right: 16px;
}

.add-card .icon {
    cursor: pointer;
    color: $grafit;
}

.add-card .icon:hover {
    color: $grey-lg-text;
}

.referral > .grid {
    width: 100%;
}

.referral > .grid > .cell {
    padding: 0;
}

.referral .description p {
    color: $grafit;
    font-size: 14px;
    line-height: 18px;
}

.referral .description p:not(:last-child) {
    margin-bottom: 16px;
}

.referral .referral-link-card,
.referral .promo-code-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px !important;
    border: 1px solid #ebeff6;
    border-radius: $border-sm;
}

.referral .referral-link-card > .title,
.referral .promo-code-wrap > .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
}

.referral .referral-link-card > .link {
    color: $black;
    font-size: 16px;
    font-weight: 400;
    background-color: #ebeff6;
    padding: 12px 16px;
    margin-bottom: 16px;
    border-radius: $border-sm;
}

.referral > .border {
    width: calc(100% - 32px);
    margin: 24px 16px;
}

.referral .referral-accruals > .title,
.referral .cash-back-accruals > .title {
    color: #2a5885;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 32px;
}

.referral .referral-details,
.referral .cash-back-details {
    background-color: #ebeff6;
    padding: 12px 16px !important;
    border-radius: $border-sm;
}

hr {
    opacity: 0.5;
}

.referral .referral-details > .description,
.referral .cash-back-details > .description {
    color: $grafit;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
}

.referral .referral-details > .title,
.referral .cash-back-details > .title,
.referral .referral-details > .total,
.referral .cash-back-details > .total {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
}

.referral .referral-details > .total > .code,
.referral .cash-back-details > .total > .code {
    font-weight: 400;
}

.referral .referral-details > .history,
.referral .cash-back-details > .history {
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
}

.referral .promo-code-wrap {
    margin-top: 32px;
}

.referral .promo-code-wrap > .title {
    margin-bottom: 0;
}

.referral .access-denied {
    color: $grafit;
    font-size: 16px;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.referral .access-denied a {
    color: #fff;
}

.linkButton {
    margin-left: 1rem;
}

.referral .access-denied .user-name {
    margin-right: 0.5rem;
    font-weight: 500;
    font-size: 18px;
}

.profile .section-name {
    color: $grafit;
    font-size: 20px;
    margin: 16px;
    margin-left: 0px;
}

.settings > .grid {
    width: 100%;
}

.settings > .grid > .cell {
    padding: 0 1%;
}

.settings > .grid > .cell:first-child {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-self: flex-start;
}

.settings > .grid > .cell:first-child > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
}

.settings .action {
    display: flex;
    flex-direction: row;
    margin: 24px 16px 16px 16px;
}

.email {
    color: $grafit;
    font-size: 16px;
}

.сhange-pass {
    box-shadow: -2px 0 2px -2px rgba(0, 0, 0, 0.16);
}

@media (max-width: 739px) {
    .сhange-pass {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.16);
    }
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container .card {
    position: relative;
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;
}

@media (max-width: 739px) {
    .container .card {
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }
}

.container .card:hover {
    transform: translateY(5px);
}

.container .card:before {
    content: "";
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.03);
    pointer-events: none;
    z-index: 1;
}

.circle svg {
    position: relative;
    width: 150px;
    height: 150px;
    z-index: 1000;
}

svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: $grey-lg-text;
    stroke-width: 10;
    stroke-linecap: round;
    transform: translate(5px, 5px);
}

svg circle:nth-child(2) {
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
}

.card:nth-child(1) svg circle:nth-child(2) {
    stroke-dashoffset: calc(440px - (440px * 100) / 100);
    stroke: $orange;
}

.card:nth-child(2) svg circle:nth-child(2) {
    stroke-dashoffset: calc(440 - (440 * 0) / 100);
    stroke: $green;
}

.card:nth-child(3) svg circle:nth-child(2) {
    stroke-dashoffset: calc(440 - (440 * 0) / 100);
    stroke: $blue;
}

.percent {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: inset 0 0 50px $page-wrap;
    z-index: 1;
    margin: 0 auto;
}

.percent .number {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.percent .number h2 {
    color: $grafit;
    font-weight: 600;
    font-size: 26px;
    transition: 0.5s;
}

.card:hover .percent .number h2 {
    color: $grafit;
    font-size: 30px;
}

.card:hover .percent img {
    width: 100px;
    transition: 0.5s;
}

.percent .number h2 span {
    font-size: 24px;
    color: $grafit;
}

.card:hover .percent .number h2 span {
    font-size: 24px;
    color: $grafit;
    transition: 0.5s;
}

.card:hover .percent .number h3 span {
    font-size: 24px;
    color: $grafit;
    transition: 0.5s;
}

.text {
    position: relative;
    color: $grey-text;
    margin-top: 1rem;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 1px;
    transition: 0.5s;
    max-width: 200px;
}

.card:hover .text {
    color: $grey-text;
}

.description-setting {
    background-color: #ebeff6;
    padding: 2px 16px !important;
    border-radius: $border-sm;
    text-align: start !important;
    line-height: 25px;
    margin-top: 1rem;
    display: block !important;
}

.description-setting div {
    margin: 10px 0;
}

.description-setting b {
    font-weight: 500;
}

.deleteAccBtn {
    margin-top: 2rem;
}

.deleteAccBtn {
    background-color: $red !important;
}

.deleteAccBtn:hover {
    background-color: $red !important;
}

.level-image {
    width: 90px;
    margin-top: 1rem;
    transition: 0.5s;
}

.monitoring-settings {
    padding: 10px 0px;
}



.level-stars {
    display: flex;
    justify-content: space-around;
}

.level-stars li {
    margin: 5px 3px;
}

.settings-grid {
    align-items: flex-start;
}

@media (max-width: 550px) {
    .info-block {
        flex-direction: column !important;
        align-items: stretch !important;

        margin-bottom: 20px;
    }

    .info-block_description {
        margin: 0px;
        margin-top: 20px;
        width: auto;
    }
    .info-block_reverse {
        flex-direction: column-reverse !important;
    }
}

.user-email {
    margin: 16px;
    font-size: 17px;
    color: #565656;
}
.user-email_section {
    margin-bottom: 10px;
}
.profile .user-email_section-desc {
    margin: 10px 0px;
    font-size: 15px;
    line-height: 20px;
    color: $grafit;
}

.resend-mail-btn {
    padding: 5px 8px;
}

.profile-view .other-col,
.profile-view .settings-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>
