export default function validateInputGive() {
    this.validations.give.isValid = true;
    this.validations.give.text = "";

    let exchange = this.exchange;
    if (!exchange) return;

    let give = exchange.currency.give;

    if (give.EReserve) {
        if (this.amount.give !== 0 && this.amount.give > give.EReserve) {
            this.validations.give.isValid = false;
            this.validations.give.text = this.$t("exchanger.validateInputGive.error", { reserve: give.EReserve, code: give.code });
        }
    }
}
