<template>
    <div class="technical-break">
        <img alt="Рисунок технического перерыва" class="image" src="../../assets/coding.png" />
        <div class="title">
            {{ $t("technicalBreak.title") }}
        </div>

        <div class="date">
            {{ date }}
        </div>

        <div class="sub-title">
            {{ $t("technicalBreak.desc") }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            now: new Date(),
            timer: ""
        };
    },
    props: {
        technicalBreak: Object,
        setTechnicalBreak: Function
    },
    computed: {
        date() {
            if (this.technicalBreak.enabled) {
                const diff = new Date(this.technicalBreak.stopped) - this.now;

                if (diff < 10000) {
                    if (diff < 1000) {
                        this.setTechnicalBreak(Object.assign({}, this.technicalBreak, { enabled: false }));
                        return;
                    }
                    return this.$t("technicalBreak.timer.turnOn");
                }

                const sec = Math.floor(diff / 1000),
                    min = Math.floor(diff / 60000),
                    hour = Math.floor(diff / 3600000);
                if (sec < 60) {
                    return this.$t("technicalBreak.timer.sec", { sec });
                }
                if (min < 60) {
                    return this.$t("technicalBreak.timer.min", { min, sec: sec % 60 });
                }
                if (hour < 24) {
                    return this.$t("technicalBreak.timer.hour", { min: min % 60, hour });
                }
            }
            return null;
        }
    },
    created() {
        this.timer = setInterval(() => {
            this.now = new Date();
        }, 1000);
    },
    estroyed() {
        clearInterval(this.timer);
    }
};
</script>

<style lang="scss" scoped>
.technical-break {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 32px);
    // position: absolute;
    background-color: $white;
    border-radius: 6px;
    padding: 8% 16px 16px 16px;
    margin: 16px;
    opacity: 0.88;
    z-index: 994;
}

.technical-break > .image {
    width: 100%;
    max-width: 240px;
    margin-bottom: 48px;
}

.technical-break > .title {
    text-align: center;
    color: #323232;
    font-size: 32px;
    margin-bottom: 24px;
}

.technical-break > .date {
    text-align: center;
    color: #323232;
    font-size: 20px;
    margin-bottom: 16px;
}

.technical-break > .sub-title {
    text-align: center;
    max-width: 640px;
    color: #323232;
    font-size: 18px;
    line-height: 24px;
}
</style>
