import axios from "axios";

const state = {
    technicalBreak: {
        enabled: false,
        started: null,
        stopped: null
    }
};

const actions = {
    async fetchBreak({ commit }) {
        try {
            const response = await axios.get("service/break");
            commit("SET_BREAK", response.data);
        } catch (e) {
            console.error(e);
        }
    }
};

const mutations = {
    SET_BREAK(state, technicalBreak) {
        state.technicalBreak = technicalBreak;
    }
};

const getters = {
    technicalBreak(state) {
        return state.technicalBreak;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
