<template>
    <div class="discount-page">
        <h3 class="title">
            Система скидок
        </h3>
        <!-- <div v-if="!user.verified" class="referral">
            <div class="not-verified">
                <span class="user-name">{{ user.name || user.email }},</span>
                <span class="description">для доступа к данному разделу Вам неообходимо активировать свой аккаунт.</span>
            </div>
        </div>
        <div v-else class="content">
            <Description />
            <Statistics v-if="discountData" :discountList="discountData.percent" />
            <Plans v-if="discountData" :discountData="discountData.range" />
            <Percent v-if="discountData" :percentData="discountData.percent" />
        </div> -->
    </div>
</template>

<script>
import DiscountService from "../../../services/DiscountService";
// import Description from "./Description.vue";
// import Plans from "./Plans.vue";
// import Percent from "./Percent.vue";
// import Statistics from "./Statistics.vue";
import { mapGetters } from "vuex";

export default {
    components: {
        // Description,
        // Plans,
        // Percent,
        // Statistics
    },
    data() {
        return {
            discountData: null
        };
    },
    watch: {
        discountData() {
            // console.log(this.discountData, "discountData");
        }
    },
    computed: {
        ...mapGetters(["user"])
    },
    mounted() {
        DiscountService.getData().then(r => {
            this.discountData = r.data;
        });
    }
};
</script>

<style lang="scss" scoped>
.discount-page {
    background-color: $white;
    margin: 16px;
    padding: 16px 8px;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

.discount-page .title {
    color: $grafit;
    font-size: 26px;
    margin: 0 16px 8px;
}

.discount-page .not-verified {
    color: $grafit;
    font-size: 16px;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.discount-page .not-verified .user-name {
    font-size: 18px;
}
.discount-page .content {
    margin: 0 16px 24px 16px;
}
</style>
