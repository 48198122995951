<template>
    <div class="create_order-promocode-section">
        <label @click="show = !show">
            <p class="title">
                {{ $t("giftPromocode.title") }}
            </p>
            <img :class="[{ 'show-arrow': true, visible: show }]" :alt="$t('giftPromocode.showText')" src="../../assets/next.png" />
        </label>
        <div :class="{ 'promo-section': true, 'promo-section-show': show }">
            <div :class="[{ 'input-block': true, 'input-block_show': true }]">
                <MyInput @blur="checkCodeHandler" class="promocode-input" v-model.trim="code" :label="$t('giftPromocode.input')" input-type="text" />
            </div>
            <p v-if="error" class="promocode-error">{{ error }}</p>
        </div>
    </div>
</template>

<script>
import MyInput from "../../components/ui/input.vue";
import axios from "axios";

export default {
    components: {
        MyInput
    },
    props: {
        setPromocode: Function
    },
    data() {
        return {
            show: false,
            code: "",
            error: ""
        };
    },
    watch: {
        code() {
            this.error = "";
        }
    },
    methods: {
        checkCodeHandler() {
            if (this.code) {
                this.checkPromocodeFetch();
            }
        },
        checkPromocodeFetch() {
            // let self = this;
            axios
                .post("/gift-promocode/check", { code: this.code })
                .then(r => {
                    this.setPromocode(this.code, r.data.sum);
                })
                .catch(e => {
                    this.error = e.response.data.message;
                    this.setPromocode("");
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.create_order-promocode-section {
    padding: 4px 16px;
}

.create_order-promocode-section label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #565656;
}

.create_order-promocode-section label .title {
    font-size: 16px;
}

.create_order-promocode-section label .show-arrow {
    transform: rotate(90deg);
    transition: transform 0.2s;
    width: 20px;
}
.create_order-promocode-section label .show-arrow.visible {
    transform: rotate(-90deg);
}
.create_order-promocode-section .promo-section {
    transition: height 0.2s;
    overflow: hidden;
    height: 0px;
}

.create_order-promocode-section .promo-section-show {
    height: 100px;
}

.create_order-promocode-section .promocode-error {
    color: red;
    font-size: 14px;
}
</style>
