export default function() {
    let filter = this.giveFilterList;
    let active = this.giveActiveFilter;

    if (this.pairLink && this.pairLink.exc) {
        this.giveActiveFilter = this.pairLink.exc.give.type;
    } else if (!active && filter.length !== 0) {
        this.giveActiveFilter = filter[0].id;
    } else if (active && filter.length !== 0 && filter.findIndex(f => f.id === this.giveActiveFilter) == -1) {
        this.giveActiveFilter = filter[0].id;
    }
}
