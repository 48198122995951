<template lang="">
    <img :alt="$t('popupCard.addCard.photoExample')" class="cardPhotoExample" src="../../../assets/card.jpg" />
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.cardPhotoExample {
    display: block;
    width: 45%;
    margin: 20px auto;
}
</style>
