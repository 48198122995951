import axios from "axios";

export default {
    state: {
        course: null
    },
    actions: {
        async fetchCourse({ commit }) {
            try {
                const response = await axios.get("service/market-course");
                commit("SET_COURSE", response.data);
            } catch (e) {
                console.error(e);
            }
        }
    },
    mutations: {
        SET_COURSE(state, course) {
            state.course = course;
        }
    },
    getters: {
        getCourse(state) {
            return state.course;
        }
    }
};
