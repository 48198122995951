import axios from "axios";

export default async function fetchSiteCodes() {
    try {
        let { data } = await axios.get("/currencies/city-codes");
        this.cityCodes = data;
    } catch (e) {
        console.error(e);
    }
}
