export default function takeCurrencyMultinet() {
    let takeCurrency = this.exchange.currency.take;

    if (takeCurrency.isMultinet && takeCurrency.crypto) {
        let netList = this.currenciesTake.filter(c => c.crypto && c.isMultinet && c.code == takeCurrency.code);
        if (netList.length > 1) {
            return netList;
        }
        return [];
    } else {
        return [];
    }
}
