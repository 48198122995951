export default function() {
    let isValid = true;
    let take = this.exchange.currency.take;
    let give = this.exchange.currency.give;

    this.$t("exchanger.validationFields.qiwiError");

    if (take.uniqueType === "qiwi" || give.uniqueType === "qiwi") {
        if (this.mobileNumber.replace(/\D/g, "").length < 10) {
            this.validations.mobileNumber = {
                text: this.$t("exchanger.validationFields.qiwiError"),
                isValid: false
            };
            isValid = false;
        }
    }
    this.fields.take.forEach(field => {
        if (isValid) {
            if (field.value == undefined || field.value.replace(/\s+/g, "").length === 0) {
                this.validationTakeFields[field._id] = {
                    isValid: false,
                    text: this.$t("exchanger.validationFields.fieldError", { field: field.name })
                };
                isValid = false;
            }
        }
    });
    this.fields.give.forEach(field => {
        if (isValid) {
            if (field.value == undefined || field.value.replace(/\s+/g, "").length === 0) {
                this.validationGiveFields[field._id] = {
                    isValid: false,
                    text: this.$t("exchanger.validationFields.fieldError", { field: field.name })
                };
                isValid = false;
            }
        }
    });
    return isValid;
}
