export default function inputGive(amount) {
    let a = +amount;

    if (!a) {
        a = 0;
        this.amount.give = a;
    }


    this.calculateTake(a);

    this.lastInputActive = "give";
}
