<template lang="">
    <div class="qiwiChoosePayment">
        <p class="qiwiChoosePayment_title">
            {{ $t("popupOrder.paymentOrder.paymentMethod") }}
        </p>
        <div class="qiwiChoosePayment_selectWrap">
            <span class="qiwiChoosePayment_linkPay" referrerpolicy="origin" target="_blank" @click="() => changeShowQiwiPaymentInfo('links')">
                {{ $t("popupOrder.paymentOrder.paymentLinkBtn") }}
            </span>
            или
            <span class="qiwiChoosePayment_direct" @click="() => changeShowQiwiPaymentInfo('fields')">
                {{ $t("popupOrder.paymentOrder.paymentDirectBtn") }}
            </span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        changeShowQiwiPaymentInfo: Function
    }
};
</script>
<style lang="scss" scoped>
.qiwiChoosePayment {
    margin: 15px 0px;
}
.qiwiChoosePayment_title {
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 600;
}
.qiwiChoosePayment_selectWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.qiwiChoosePayment_linkPay {
    color: #3597fa;
    cursor: pointer;
    margin-right: 5px;
}

.qiwiChoosePayment_direct {
    color: #3597fa;
    cursor: pointer;
    margin-left: 5px;
}

.qiwiChoosePayment_linkPay:hover,
.qiwiChoosePayment_direct:hover {
    text-decoration: underline;
}
</style>
