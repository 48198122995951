<template lang="">
    <div class="addCardSection">
        <ModalContentWrap :headerName="$t('popupCard.addCard.title')" :closeHandler="closeHandler">
            <div>
                <StepItem :number="1" :text="$t('popupCard.addCard.step1title')" />
                <my-input v-model.trim="number" :label="$t('popupCard.addCard.form.cardNumber')" :validation="numberValidation"></my-input>
                <my-input v-model.trim="name" :label="$t('popupCard.addCard.form.fullName')" :validation="nameValidation"></my-input>
                <StepItem :number="2" :text="$t('popupCard.addCard.step2title')" />
                <div class="addCardSection_description">
                    <p>
                        {{ $t("popupCard.addCard.desc1") }}
                    </p>

                    {{ $t("popupCard.addCard.desc2") }}
                    <br />
                    {{ $t("popupCard.addCard.desc3") }}

                    <br />
                    {{ $t("popupCard.addCard.desc4") }}
                </div>
                <CardPhotoExample v-if="!image" />
                <StepItem :number="3" :text="addImageText" />
                <ImageSelect :image="image" :file="file" :removeImage="removeImage" :isScreenMobile="isScreenMobile" :setImage="i => (this.image = i)" :setFile="i => (this.file = i)" />
            </div>
            <template v-slot:btns>
                <div class="addCardSection_btnsWrap">
                    <my-button class="outline" :outline="true" :value="$t('popupCard.addCard.confirm.cancel')" @click.native="closeHandler"></my-button>
                    <my-button :value="$t('popupCard.addCard.confirm.add')" @click.native="addHandler" :spinner="spinner"></my-button>
                </div>
            </template>
        </ModalContentWrap>
    </div>
</template>
<script>
// Components
import ModalContentWrap from "../ModalContentWrap/ModalContentWrap.vue";
import ImageSelect from "../ui/ImageSelect.vue";
// Computeds
import addImageText from "./computeds/addImageText";
import isScreenMobile from "./computeds/isScreenMobile";

// UI
import StepItem from "../ui/StepItem.vue";
import MyInput from "../ui/input";
import MyButton from "../ui/button.vue";

// services
import CardService from "../../services/CardService";

// Main Ui
import CardPhotoExample from "./UI/CardPhotoExample.vue";

export default {
    data() {
        return {
            image: null,
            file: null,
            number: "",
            name: "",
            numberValidation: {
                isValid: true,
                text: ""
            },
            nameValidation: {
                isValid: true,
                text: ""
            },
            spinner: false
        };
    },
    watch: {
        number() {
            if (this.number) {
                this.numberValidation.isValid = true;
                this.number = `${this.number.replace(/\s+/g, "").replace(/(.{4})(.{4})(.{4})(.{4,6})/, "$1 $2 $3 $4")}`;
                if (this.number.length >= 22) {
                    this.number = this.number.substr(0, 20);
                    return this.number;
                }
            }
        },
        name() {
            if (this.name) {
                this.nameValidation.isValid = true;
            }
        }
    },
    props: {
        closeHandler: Function,
        setCard: Function
    },
    methods: {
        removeImage() {
            this.file = null;
            this.image = null;
        },
        addHandler() {
            if (this.spinner) return;

            if (!this.number || this.number.length <= 12 || this.number.length > 19) {
                this.numberValidation.isValid = false;
                this.numberValidation.text = this.$t("popupCard.addCard.validation.cardNumber");
                this.spinner = false;
            } else if (!this.name) {
                this.nameValidation.isValid = false;
                this.nameValidation = this.$t("popupCard.addCard.validation.name");
                this.spinner = false;
            }
            if (!this.file) return;

            this.spinner = true;

            let form = new FormData();
            form.append("number", this.number);
            form.append("name", this.name);
            form.append("image", this.file);

            CardService.add(form)
                .then(r => {
                    this.setCard(r.data);
                })
                .catch(e => {
                    if (e.response && e.response.data && e.response.data.card) {
                        this.numberValidation.isValid = false;
                        this.numberValidation.text = e.response.data.error;
                    }
                })
                .finally(() => {
                    this.spinner = false;
                });
        }
    },
    computed: {
        addImageText,
        isScreenMobile
    },

    components: {
        ImageSelect,
        ModalContentWrap,
        StepItem,
        MyInput,
        CardPhotoExample,
        MyButton
    },
    beforeDestroy() {
        this.image = null;
        this.file = null;
        this.spinner = false;
        this.number = "";
        this.name = "";
        this.numberValidation = {
            isValid: true,
            text: ""
        };
        this.nameValidation = {
            isValid: true,
            text: ""
        };
    }
};
</script>
<style lang="scss" scoped>
.addCardSection {
}

.addCardSection_description {
    font-size: 14px;
    line-height: 20px;
    background-color: $white;
    padding: 16px;
}

.addCardSection_description > p {
    margin-bottom: 16px;
}
.addCardSection_btnsWrap {
    display: flex !important;
    justify-content: center;
}

.addCardSection_btnsWrap button:first-child {
    margin-right: 10px;
    display: inline-block;
}
.addCardSection_btnsWrap button:last-child {
    margin-left: 10px;
    display: inline-block;
}
</style>
