<template>
    <div class="partner">
        <div class="partner-close" @click="closeCooperation()">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 -4 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
        </div>

        <div class="wrapper">
            <img alt="" class="partner-icon" src="../assets/table.png" />

            <h1 class="partner-header">
                {{ $t("cooperationModal.title") }}
            </h1>
            <p class="partner-description">
                {{ $t("cooperationModal.desc1") }}
            </p>

            <p class="partner-description">
                {{ $t("cooperationModal.desc2") }}
            </p>

            <p class="partner-description">
                {{ $t("cooperationModal.desc3") }}
            </p>

            <p class="partner-download_coursers">
                <a target="_blank" href="https://ex-bank.cc/assets/rates.xml">
                    {{ $t("cooperationModal.downloadCourses") }}
                </a>
            </p>

            <Mybutton @click.native="openCooperationSignup()" class="partner-signup full-width" :value="$t('cooperationModal.registerAsMonitoring')"></Mybutton>
        </div>
    </div>
</template>

<script>
import Mybutton from "../components/ui/button";

export default {
    props: {
        closeCooperation: Function,
        openCooperationSignup: Function
    },
    components: {
        Mybutton
    }
};
</script>

<style scoped lang="scss">
.partner {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.partner-close {
    align-self: flex-end;
    cursor: pointer;
}

.partner-icon {
    width: 100px;
    margin: 20px auto;
}

.partner-header {
    font-size: 24px;
    margin-bottom: 32px;
    color: #161616;
}

.partner-description {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 32px;
}

.partner-download_coursers {
    text-decoration: underline;
    margin-bottom: 32px;
    font-size: 16px;
    cursor: pointer;
    color: $link;
    transition: all 0.32s;

    &:hover {
        color: #2a5885;
    }
}

.partner-signup {
    padding: 15px 0;
}

.wrapper {
    padding: 0rem 1rem 1rem 1rem;
    background-color: #fff;
    color: #4d4d4d;
}
</style>
