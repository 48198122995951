<template>
    <div class="cooopearation-signup">
        <div class="cooopearation-signup_close" @click="closeCooperationSignup()">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 -4 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
        </div>
        <div class="wrapper">
            <h1 class="partner-header">
                {{ $t("partnerReg.title") }}
            </h1>

            <form @submit.prevent="submit" class="cooopearation-signup_form">
                <Myinput v-model.trim="name" :label="$t('partnerReg.name')" :validation="validations.name" />

                <Myinput v-model.trim="monitoring" :label="$t('partnerReg.monitoringName')" :validation="validations.monitoring" />

                <Myinput v-model.trim="email" label="E-mail" inputType="email" name="email" @input="formatEmail" :validation="validations.email" />

                <Myinput v-model.trim="password" :label="$t('partnerReg.password')" inputType="password" :validation="validations.password" />
                <Myinput v-model.trim="confirmPassword" :label="$t('partnerReg.confirmPassword')" inputType="password" :validation="validations.confirmPassword" />

                <Agree v-model="agree" class="cooopearation-signup_agree" />

                <p v-if="agreeError" class="cooopearation-signup_agree-error">{{ agreeError }}</p>

                <Mybutton :spinner="spinner" class="cooopearation-signup_form-submit" :value="$t('partnerReg.regButton')"></Mybutton>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Myinput from "./ui/input";
import Mybutton from "./ui/button";
import Agree from "./agree";

export default {
    components: {
        Myinput,
        Mybutton,
        Agree
    },
    props: {
        closeCooperationSignup: Function
    },
    data() {
        return {
            name: "",
            email: "",
            monitoring: "",
            password: "",
            confirmPassword: "",
            agree: false,
            agreeError: "",
            validations: {
                name: {
                    isValid: true,
                    text: ""
                },
                email: {
                    isValid: true,
                    text: ""
                },
                password: {
                    isValid: true,
                    text: ""
                },
                confirmPassword: {
                    isValid: true,
                    text: ""
                },
                monitoring: {
                    isValid: true,
                    text: ""
                }
            },
            spinner: false
        };
    },
    computed: {
        ...mapGetters(["referralLink"])
    },
    watch: {
        name() {
            if (this.name) {
                this.validations.name.isValid = true;
            }
        },
        monitoring() {
            if (this.monitoring) {
                this.validations.monitoring.isValid = true;
            }
        },
        email() {
            if (this.email) {
                this.validations.email.isValid = true;
            }
        },
        password() {
            if (this.password) {
                this.validations.password.isValid = true;
            }
        },
        confirmPassword() {
            if (this.confirmPassword) {
                this.validations.confirmPassword.isValid = true;
            }
        },

        agree() {
            if (this.agree) {
                this.agreeError = "";
            }
        }
    },
    methods: {
        ...mapActions(["userSignup"]),
        formatEmail(email) {
            this.email = email.toLowerCase();
        },
        validateEmail(email) {
            const re = /\S+@\S+\.\S+/;
            return re.test(email);
        },

        submit() {
            if (this.spinner) return;
            if (this.name.length < 3) {
                this.validations.name.isValid = false;
                this.validations.name.text = this.$t("partnerReg.validation.name");
            } else if (this.monitoring.length < 3) {
                this.validations.monitoring.isValid = false;
                this.validations.monitoring.text = this.$t("partnerReg.validation.monitoringName");
            } else if (!this.validateEmail(this.email)) {
                this.validations.email.isValid = false;
                this.validations.email.text = this.$t("partnerReg.validation.email");
            } else if (this.password.length < 6) {
                this.validations.password.isValid = false;
                this.validations.password.text = this.$t("partnerReg.validation.password");
            } else if (this.password !== this.confirmPassword) {
                this.validations.confirmPassword.isValid = false;
                this.validations.confirmPassword.text = this.$t("partnerReg.validation.passwordConfirm");
            } else if (!this.agree) {
                this.agreeError = this.$t("partnerReg.validation.agreement");
            } else {
                this.spinner = true;
                let data = {
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    referralLink: this.referralLink,
                    partners: true,
                    monitoring: this.monitoring
                };
                this.userSignup({ data, successHandler: this.successHandler, errorHandler: this.errorHandler });
            }
        },
        successHandler() {
            this.spinner = false;
            this.name = "";
            this.email = "";
            this.password = "";
            this.confirmPassword = "";

            this.$router.push({
                name: "profile",
                params: { view: "referral" }
            });
            this.closeCooperationSignup();
        },
        errorHandler(data) {
            if (data.email) {
                this.validations.email.text = data.error;
                this.validations.email.isValid = false;
            } else {
                console.error(data);
            }
            this.spinner = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.cooopearation-signup {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 500px;
}

@media (max-width: 600px) {
    .cooopearation-signup {
        width: auto;
    }
}

.cooopearation-signup_close {
    align-self: flex-end;
    cursor: pointer;
}

.cooopearation-signup_form {
    text-align: left;
}

.cooopearation-signup_agree {
    margin: 20px 2px 10px 2px;
}
.cooopearation-signup_agree-error {
    color: #ff1a1a;
    margin: 18px 0;
}

.cooopearation-signup_form-submit {
    width: 100%;
    margin-top: 20px;
}
.wrapper {
    padding: 0rem 1rem 1rem 1rem;
    background-color: #fff;
    color: #4d4d4d;
}
.partner-header {
    font-size: 24px;
    margin-bottom: 32px;
    color: #161616;
}
</style>
