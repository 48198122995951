<template>
    <div v-html="content"></div>
</template>
<script>
import { i18n } from "./i18n";
import store from "./store/index";

export default {
    computed: {
        content() {
            let getAd = store.getters.getAd;

            let locale = i18n.locale;

            let result = locale == "ru" ? getAd.rus : getAd.eng;

            return result;
        }
    }
};
</script>
<style lang=""></style>
