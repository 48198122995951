<template>
    <div class="cashback-page">
        <h3 class="title">
            {{ $t("cashbackPage.title") }}
        </h3>
        <div v-if="user.verified" class="wrap">
            <Description/>
            <Charts/>
        </div>
        <div v-else>
            <div class="noverify_wrap">
                <span class="noverify_wrap-name">{{ user.name || user.email }},</span>
                <span class="noverify_wrap-text"> {{ $t("referralPage.notVerifiedTitle") }}.</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

// Components
import Description from './Description.vue'
import Charts from './Charts.vue'

export default {
    components: {
        Description,
        Charts
    },
    // data() {
    //     return {
    //         // discountData: null
    //     };
    // },
    // watch: {},
    computed: {
        ...mapGetters(["user"])
    }
    // mounted() {}
};
</script>

<style lang="scss" scoped>
.cashback-page {
    background-color: $white;
    margin: 16px;
    padding: 16px 8px;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

.cashback-page .title {
    color: $grafit;
    font-size: 26px;
    margin: 0 16px 8px;
    margin-bottom: 0px;
}

.cashback-page .wrap {
    color: $grafit;
    font-size: 16px;
    // padding: 16px;
}

.cashback-page .noverify_wrap {
    color: $grafit;
    font-size: 16px;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.cashback-page .noverify_wrap-name {
    margin-right: 0.5rem;
    font-weight: 500;
    font-size: 18px;
}
.cashback-page .noverify_wrap-text {
    color: $grafit;
    font-size: 16px;
}

// .discount-page .not-verified {
//     color: $grafit;
//     font-size: 16px;
//     padding: 16px;
//     display: flex;
//     flex-wrap: wrap;
//     align-items: center;
// }

// .discount-page .not-verified .user-name {
//     font-size: 18px;
// }
// .discount-page .content {
//     margin: 0 16px 24px 16px;
// }
</style>
