<template lang="">
    <div class="orderClientFields">
        <h5 class="title">{{ $t("orderPage.fields.directPreName") + " " + (direct == "left" ? $t("orderPage.fields.directNameLeft") : $t("orderPage.fields.directNameRight")) }}</h5>
        <div class="currencInfoSection">
            <img alt="" class="currencyImage" :src="getCurrencyImage()" />
            <h6 class="currencyName">{{ currencyName }}</h6>
        </div>
        <div class="fieldsListWrap">
            <div class="fieldsListItem">
                <h6 class="fieldsListItemLabel">{{ $t("orderPage.fields.sum") }}:</h6>
                <h6 class="fieldsListItemValue">{{ amount }}</h6>
            </div>

            <div v-for="(item, i) in fields" :key="i" class="fieldsListItem">
                <h6 class="fieldsListItemLabel">{{ item.name }}:</h6>
                <h6 class="fieldsListItemValue">{{ item.value }}</h6>
            </div>
        </div>
    </div>
</template>
<script>
import { getCurrencyImageLink } from "../../utils/currency/getImageLink";

export default {
    props: {
        title: String,
        currencyName: String,
        currencyImage: String,
        fields: Array,
        amount: Number,
        direct: String
    },
    methods: {
        getCurrencyImage() {
            return getCurrencyImageLink(this.currencyImage);
        }
    }
};
</script>
<style lang="scss" scoped>
.orderClientFields {
    padding: 10px 24px;
}
.orderClientFields .title {
    font-weight: 600;
}
.orderClientFields .currencInfoSection {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.currencInfoSection .currencyImage {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 16px;
    -o-object-fit: cover;
    object-fit: cover;
}

.currencInfoSection .currencyName {
    margin-left: 3px;
    font-size: 16px;
    font-weight: 600;
}

.orderClientFields .fieldsListWrap {
    margin-top: 20px;
}

.fieldsListItem {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 15px;
}
.orderClientFields .fieldsListItemLabel {
    margin-right: 5px;
}

.orderClientFields .fieldsListItemValue {
    font-weight: 600;
    word-break: break-all;
}

// .orderClientFields
</style>
