export default function mobileNumberHandler(e) {
    this.validations.mobileNumber = {
        isValid: true,
        text: ""
    };
    let value = e;
    if (value.length === 0 || value.length === 1) {
        this.selectedCountry = "";
    }
    if (value) {
        let r = value.replace(/\D/g, "").match(/(\d?)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,3})/);

        let stringLength = r[0].length;
        let x;
        if (stringLength < 12) {
            x = value.replace(/\D/g, "").match(/(\d?)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,3})/);
        } else {
            x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,4})/);
        }

        let regionCode;
        if (stringLength < 12) {
            regionCode = x[1] + x[2];
        } else {
            regionCode = x[0].slice(0, 4);
        }

        let country = null;

        for (let key in this.country) {
            let countryCode = this.country[key].code;
            let countryCodeLength = countryCode.length;
            let regionCodeLength = regionCode.length;

            let minLength = countryCodeLength < regionCodeLength ? countryCodeLength : regionCodeLength;

            if (minLength === 0) break;

            let regionTrimm = regionCode.slice(0, minLength);
            let countryTrimm = countryCode.slice(0, minLength);

            // console.log(regionTrimm, 'r', countryTrimm, 'c')

            if (regionTrimm === countryTrimm) {
                country = this.country[key];
            }
        }
        const getMinimalMatch = (number, length) => {
            let findCountry;

            for (let key in this.country) {
                let c = this.country[key];
                let trimmNumber = number.slice(0, length);
                let trimmCode = c.code.slice(0, length);

                // console.log(trimmNumber, trimmCode, "cycle");

                if (trimmNumber === trimmCode) {
                    findCountry = c;
                }
            }

            if (findCountry) {
                return { findCountry, length };
            } else {
                return false;
            }
        };

        // +380 96 661 0595
        // '374', '33', '553', '5342'//
        if (country) {
            this.selectedCountry = this.getImgUrl(country.name);
            this.selectedCountryAlt = country.alt;

            let mobileNumber;

            if (stringLength < 12) {
                mobileNumber = !x[3] ? "+" + x[1] + " " + x[2] : "+" + x[1] + " " + "(" + x[2] + ") " + x[3] + (x[4] ? "-" + x[4] : "") + (x[5] ? "-" + x[5] : "");
            } else {
                mobileNumber = !x[3] ? "+" + x[1] + "" + x[2] : "+" + x[1] + " " + x[2] + " " + x[3] + (x[4] ? " " + x[4] : "") + (x[5] ? " " + x[5] : "");
            }

            if (mobileNumber.length <= 3) {
                mobileNumber = mobileNumber.replace(/\s/g, "");
            }

            this.mobileNumber = mobileNumber;
        } else {
            let findMinimalMatch3 = getMinimalMatch(x.input, 3);
            let findMinimalMatch2 = getMinimalMatch(x.input, 2);
            let findMinimalMatch1 = getMinimalMatch(x.input, 1);
            let finded = findMinimalMatch3 || findMinimalMatch2 || findMinimalMatch1;
            if (finded) {
                let formattedNumber = x.input.slice(0, finded.length);
                let f;
                if (stringLength < 12) {
                    f = formattedNumber.replace(/\D/g, "").match(/(\d?)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,3})/);
                } else {
                    f = formattedNumber.replace(/\D/g, "").match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,4})/);
                }

                let mobileNumber;

                if (stringLength < 12) {
                    mobileNumber = !f[3] ? "+" + f[1] + " " + f[2] : "+" + f[1] + " " + "(" + f[2] + ") " + f[3] + (f[4] ? "-" + f[4] : "") + (f[5] ? "-" + f[5] : "");
                } else {
                    mobileNumber = !f[3] ? "+" + f[1] + "" + f[2] : "+" + f[1] + " " + f[2] + " " + f[3] + (f[4] ? " " + f[4] : "") + (f[5] ? " " + f[5] : "");
                }

                if (mobileNumber.length <= 3) {
                    mobileNumber = mobileNumber.replace(/\s/g, "");
                }

                this.mobileNumber = mobileNumber;
                this.selectedCountry = this.getImgUrl(finded.findCountry.name);
                this.selectedCountryAlt = finded.findCountry.alt;
            } else {
                this.mobileNumber = "+";
            }
        }
    }
}
