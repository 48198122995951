<template>
    <div @click="changeLangHandler" :title="this.$i18n.locale == 'ru' ? 'Русский' : 'English'" class="lang-switcher">
        <p class="name">{{ name }}</p>
        <img style="width: 35px;" :src="imgUrl" :alt="name" />
        <!-- <div class="lang-switcher_triange"/> -->
    </div>
</template>

<script>
export default {
    computed: {
        name() {
            return this.$i18n.locale.toUpperCase();
        },
        imgUrl() {
            var images = require.context("../assets/flags/", false, /\.png$/);
            let fileName = this.$i18n.locale === "ru" ? "ru-flag" : "en-flag";
            return images("./" + fileName + ".png");
        },

    },
    methods: {
        changeLangHandler() {
            let newLocale = this.$i18n.locale === "ru" ? "en" : "ru";
            // this.$i18n.locale = newLocale;
            localStorage.setItem("locale", newLocale);
            this.$router.go();
        }
    }
};
</script>

<style lang="sass" scoped>
.lang-switcher
    margin-right: 30px
    cursor: pointer
    display: flex
    align-items: center
    transition: opacity 0.4s
    padding: 0 3px
    border-radius: 2px
    &:hover
        opacity: 0.7

    .lang-switcher_triange
        margin-left: 5px
        width: 0
        height: 0
        border-left: 6px solid transparent
        border-right: 6px solid transparent
        border-top: 6px solid #c1c1c1
    .name
        margin-right: 7px
        color: #565656
</style>
