export default function getPromocodeGiveAmount() {
    let { rub } = this.promocodeInfo;
    if (!rub) return "";

    const exchange = this.exchange;

    if (!exchange) return "";

    const givePrice = exchange.currency.give.evalue;

    let result = Math.floor((rub / givePrice) * this.precision.give) / this.precision.give;
    return result;
}
