<template>
    <div class="order-exchange">
        <div class="grid grid--no-spacing">
            <div class="cell cell--5-col cell--3-col-tablet cell--4-col-phone currency">
                <img alt="" class="image" :src="image.take" v-if="image.take" />
                <div>
                    <div class="amount">{{ pair.take.amount }} {{ pair.take.code }}</div>
                    <div class="name">{{ pair.take.name }}</div>
                </div>
            </div>

            <div class="cell cell--2-col cell--2-col-tablet cell--4-col-phone icon-exchange">
                <svg v-if="mode === 'change'" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="-1 4 24 24" fill="none" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-down change-arrow">
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <polyline points="19 12 12 19 5 12"></polyline>
                </svg>

                <svg v-else xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-repeat">
                    <polyline points="17 1 21 5 17 9"></polyline>
                    <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
                    <polyline points="7 23 3 19 7 15"></polyline>
                    <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
                </svg>
            </div>

            <div class="cell cell--5-col cell--3-col-tablet cell--4-col-phone currency">
                <img alt="" class="image" :src="image.give" v-if="image.give" />
                <div>
                    <div class="amount">{{ pair.give.amount }} {{ pair.give.code }}</div>
                    <div class="name">{{ pair.give.name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pair: Object,
        mode: String
    },

    computed: {
        image() {
            return {
                take: this.pair ? `${this.URL.server}/images/${this.pair.take.image}.png` : "",
                give: this.pair ? `${this.URL.server}/images/${this.pair.give.image}.png` : ""
            };
        }
    }
};
</script>

<style lang="scss" scoped>
.order-exchange .time {
    font-size: 14px;
}
.order-exchange .grid {
    width: 100%;
    align-items: center;
}
.order-exchange .grid > div {
    margin: 16px 0px;
}
.order-exchange .currency {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.order-exchange .currency .image {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 8px;
    -o-object-fit: cover;
    object-fit: cover;
}
.order-exchange .currency .amount {
    font-size: 20px;
    margin-bottom: 8px;
}
.order-exchange .currency .name {
    color: $grafit;
    font-size: 16px;
}
.order-exchange .icon-exchange {
    color: #acacac;
}
.change-arrow {
    transform: rotate(-90deg);
}
</style>
